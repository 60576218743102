import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { Grid, Form, Button } from 'semantic-ui-react'
import { useAxios, useToolbarDispatch } from 'contexts'
import { Exam } from 'types'
import Textbox from 'component/control/textbox'
import Layout from 'component/layout'

type Props =
  | {
      mode: 'new'
      exam_id?: string
      onSave?: () => void
      title?: string
    }
  | {
      mode: 'view' | 'edit' | 'delete'
      exam_id: string
      onSave?: () => void
      title?: string
    }

type Inputs = Omit<Exam, 'id'>

const InputForm: React.FC<Props> = props => {
  const { mode, exam_id } = props

  const SELF_PATH = `/crud/exam`
  const BASE_PATH = `/crud/exam`

  const locked = mode === 'view' || mode === 'delete' ? true : false

  const { axios } = useAxios()
  const history = useHistory()
  const toolbarDispatch = useToolbarDispatch()

  const { handleSubmit, errors, control, setValue } = useForm<Inputs>({
    criteriaMode: 'all',
  })

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    toolbarDispatch({
      type: 'SET',
      title: '試験',
    })
  }, [])

  const onSubmit = (model: Inputs) => {
    switch (mode) {
      case 'new':
        postApi(model)
        break
      case 'edit':
        patchApi(model)
        break
      case 'delete':
        deleteApi()
        break
    }
  }

  useEffect(() => {
    const getApi = async () => {
      setLoading(true)
      const { data, error } = await axios.get<Exam>(`${BASE_PATH}/${exam_id}`)
      setLoading(false)
      if (error) {
        console.log('get error', error)
        return
      }

      const { name, image_url } = data || {}
      setValue('name', name, { shouldValidate: true })
      setValue('image_url', image_url, { shouldValidate: true })
    }

    if (mode !== 'new') getApi()
  }, [mode, exam_id, setValue])

  const postApi = async (model: Inputs) => {
    setLoading(true)
    const { data, error } = await axios.post<Exam>(`${SELF_PATH}`, model)
    setLoading(false)

    if (error) {
      console.log('post error', error)
      return
    }
    console.log('post data:', data)
    history.push(BASE_PATH)
  }

  const patchApi = async (model: Inputs) => {
    setLoading(true)
    const { data, error } = await axios.patch<Exam>(
      `${SELF_PATH}/${exam_id}`,
      model
    )
    setLoading(false)

    if (error) {
      console.log('patch error', error)
      return
    }
    console.log('patch data:', data)
    history.push(BASE_PATH)
  }

  const deleteApi = async () => {
    setLoading(true)
    const { data, error } = await axios.delete<Exam>(`${SELF_PATH}/${exam_id}`)
    setLoading(false)

    if (error) {
      console.log('delete error', error)
      return
    }
    console.log('delete data:', data)
    history.push(BASE_PATH)
  }

  return (
    <Layout.Page title='試験' loading={loading}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Field
                required
                error={errors.name ? true : false}
                disabled={locked}
              >
                <label>name</label>
                <Textbox.Base.Standard
                  control={control}
                  errors={errors}
                  name='name'
                  placeholder='enter name'
                  required
                />
              </Form.Field>

              <Form.Field
                required
                error={errors.image_url ? true : false}
                disabled={locked}
              >
                <label>image_url</label>
                <Textbox.Base.Url
                  control={control}
                  errors={errors}
                  name='image_url'
                  placeholder='enter image url'
                />
              </Form.Field>

              {mode !== 'view' && <Button type='submit' content='Submit' />}
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Layout.Page>
  )
}

export default InputForm
