import { useState, useEffect } from 'react'
import low from 'lowdb'
import LocalStorage from 'lowdb/adapters/LocalStorage'

const getLocalStorage = (key: string) => {
  const adapter = new LocalStorage(key)
  return low(adapter)
}

type useLocalStorageParameterType<T> = {
  dbkey: string
  key: string
  initial: T
}

type useLocalStorageReturnType<T> = readonly [
  T,
  React.Dispatch<React.SetStateAction<T>>,
  boolean
]

export const useLocalStorage = <T,>(
  param: useLocalStorageParameterType<T>
): useLocalStorageReturnType<T> => {
  const { dbkey, key, initial } = param
  const [busy, setBusy] = useState(false)

  const [localState, setLocalState] = useState<T>(() => {
    const restore = getLocalStorage(dbkey).get(key).value()
    return restore || initial
  })

  useEffect(() => {
    setBusy(true)
    getLocalStorage(dbkey).set(key, localState).write()
    setBusy(false)
  }, [dbkey, key, localState])

  return [localState, setLocalState, busy]
}

export default useLocalStorage
