import React, { useState, useEffect } from 'react'

import { TextAreaProps } from 'semantic-ui-react'
import { Standard, StandardProps } from './Standard'

export type AutoHeightProps = StandardProps & {
  keepOpened?: boolean
}

export const AutoHeight: React.FC<AutoHeightProps> = props => {
  const {
    control,
    name,
    rows: _rows,
    onFocus,
    onBlur,
    onChange,
    keepOpened,
  } = props
  const [rows, setRows] = useState(_rows || 1)

  useEffect(() => {
    if (!keepOpened) return

    const lines = getLines()
    if (!lines) return

    setRows(lines.length + 1)
  }, [control.getValues()?.[name], keepOpened])

  const getLines = () => {
    const value = control.getValues()?.[name]
    return value ? value.match(/\r\n|\n/g) : null
  }

  const handleOnFocus = (...args: any) => {
    const lines = getLines()
    setRows(rows => (lines ? lines.length + 1 : rows))
    onFocus && onFocus(args)
  }

  const handleOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    data: TextAreaProps
  ) => {
    const lines = getLines()
    setRows(rows => (lines ? lines.length + 1 : rows))
    onChange && onChange(event, data)
  }

  const handleOnBlur = (...args: any) => {
    if (keepOpened) return
    setRows(_rows || 1)
    onBlur && onBlur(args)
  }

  return (
    <Standard
      {...props}
      rows={rows}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
    />
  )
}

export default AutoHeight
