import React from 'react'
import { Container, Header, Segment } from 'semantic-ui-react'
import './page.css'

type Props = {
  title?: string
  loading?: boolean
  basic?: boolean
}

export const Page: React.FC<Props> = props => {
  return (
    <Container>
      {props.title && (
        <Header
          as='h4'
          attached='top'
          block
          content={props.title}
        />
      )}
      <Segment
        loading={props.loading ? true : false}
        attached={props.title ? true : false}
        basic={props.basic}
        className='lightSpread'
      >
        {props.children}
      </Segment>
    </Container>
  )
}

export default Page
