import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { Standard } from './Standard'
import * as Validations from 'base/control/validations'

export type HmProps = React.ComponentProps<typeof Standard>

export const Hm: React.FC<HmProps> = props => {
  const { validate } = props

  const handleEdit = (value: string): string | null => {
    const plain = value.replace(/\u003a/g, '')
    if (!plain.match(/[0-9]{4}/)) return value

    const m = moment(plain, 'HHmm')
    if (!m.isValid()) return value

    // valid and format
    return m.format('HH:mm')
  }

  return (
    <Standard
      {...props}
      type='tel'
      editValue={handleEdit}
      validate={_.assign({}, validate, { hm: Validations.hm })}
    />
  )
}

export default Hm
