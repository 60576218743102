import React from 'react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  Legend,
  PolarRadiusAxis,
  Radar,
} from 'recharts'

// see: https://recharts.org/en-US/examples

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
]
export const SampleAreaChart: React.FC = () => {
  return (
    <ResponsiveContainer>
      <AreaChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Area
          type='monotone'
          dataKey='uv'
          stackId='1'
          stroke='#00b5ad'
          fill='#00b5ad'
        />
        <Area
          type='monotone'
          dataKey='pv'
          stackId='1'
          stroke='#21ba45'
          fill='#21ba45'
        />
        <Area
          type='monotone'
          dataKey='amt'
          stackId='1'
          stroke='#a333c8'
          fill='#a333c8'
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default SampleAreaChart

const data2 = [
  {
    subject: 'Sales',
    A: 120,
    B: 110,
    fullMark: 150,
  },
  {
    subject: 'Develop',
    A: 98,
    B: 130,
    fullMark: 150,
  },
  {
    subject: 'Human',
    A: 86,
    B: 130,
    fullMark: 150,
  },
  {
    subject: 'Accounts',
    A: 99,
    B: 100,
    fullMark: 150,
  },
  {
    subject: 'Working',
    A: 85,
    B: 90,
    fullMark: 150,
  },
  {
    subject: 'Overtime',
    A: 65,
    B: 85,
    fullMark: 150,
  },
]

export const SampleRadarChart: React.FC = () => {
  return (
    <ResponsiveContainer>
      <RadarChart data={data2}>
        <PolarGrid />
        <PolarAngleAxis dataKey='subject' />
        <PolarRadiusAxis angle={30} domain={[0, 150]} />
        <Radar
          name='Mike'
          dataKey='A'
          stroke='#a333c8'
          fill='#a333c8'
          fillOpacity={0.6}
        />
        <Radar
          name='Lily'
          dataKey='B'
          stroke='#00b5ad'
          fill='#00b5ad'
          fillOpacity={0.6}
        />
        <Legend />
      </RadarChart>
    </ResponsiveContainer>
  )
}
