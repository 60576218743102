import React from 'react'
import { Route, useParams } from 'react-router-dom'
import { MySwitch } from 'base/router'
import { ToolbarProvider } from 'contexts'
import List from './List'
import InputForm from './InputForm'

import Plactices from './Plactices'

export const BASE_PATH = '/crud/exam'

export const Exam: React.FC = () => {
  return (
    <MySwitch>
      <Route exact path={`${BASE_PATH}`} component={Top} />
      <Route exact path={`${BASE_PATH}/new`} component={New} />
      <Route exact path={`${BASE_PATH}/:exam_id/view`} component={View} />
      <Route exact path={`${BASE_PATH}/:exam_id/edit`} component={Edit} />
      <Route exact path={`${BASE_PATH}/:exam_id/delete`} component={Delete} />

      <Route path={`${BASE_PATH}/:exam_id/plactices`} component={Plactices} />
    </MySwitch>
  )
}

export default Exam

type PathParams = {
  exam_id: string
}

export const Top: React.FC = () => (
  <ToolbarProvider>
    <List />
  </ToolbarProvider>
)

export const New: React.FC = () => (
  <ToolbarProvider>
    <InputForm mode='new' />
  </ToolbarProvider>
)

export const View: React.FC = () => {
  const params = useParams<PathParams>()
  const { exam_id } = params

  return (
    <ToolbarProvider>
      <InputForm mode='view' exam_id={exam_id} />
    </ToolbarProvider>
  )
}

export const Edit: React.FC = () => {
  const params = useParams<PathParams>()
  const { exam_id } = params

  return (
    <ToolbarProvider>
      <InputForm mode='edit' exam_id={exam_id} />
    </ToolbarProvider>
  )
}

export const Delete: React.FC = () => {
  const params = useParams<PathParams>()
  const { exam_id } = params

  return (
    <ToolbarProvider>
      <InputForm mode='delete' exam_id={exam_id} />
    </ToolbarProvider>
  )
}
