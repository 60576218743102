import React from 'react'
import { Route, useParams } from 'react-router-dom'
import { MySwitch } from 'base/router'
import { ToolbarProvider } from 'contexts'

import ExamList from './ExamList'
import PlacticeList from './PlacticeList'

export const BASE_PATH = '/attend'

export const Attend: React.FC = () => {
  return (
    <MySwitch>
      <Route exact path={`${BASE_PATH}`} component={Top} />
      <Route
        exact
        path={`${BASE_PATH}/exam/:exam_id/plactices`}
        component={Plactices}
      />
    </MySwitch>
  )
}

export default Attend

type PathParams = {
  exam_id: string
}

export const Top: React.FC = () => (
  <ToolbarProvider>
    <ExamList />
  </ToolbarProvider>
)

export const Plactices: React.FC = () => {
  const params = useParams<PathParams>()
  const { exam_id } = params

  return (
    <ToolbarProvider>
      <PlacticeList exam_id={exam_id} />
    </ToolbarProvider>
  )
}
