import React from 'react'

import { NumberBase } from './NumberBase'

export type SignedIntProps = React.ComponentProps<typeof NumberBase>

export const SignedInt: React.FC<SignedIntProps> = props => (
  <NumberBase {...props} signed />
)

export default SignedInt
