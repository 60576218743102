import React, { useMemo, CSSProperties } from 'react'
import _ from 'lodash'
import {
  Table,
  TableProps,
  TableRowProps,
  TableCellProps,
  TableBodyProps,
  TableFooterProps,
  TableHeaderCellProps,
  TableHeaderProps,
} from 'semantic-ui-react'
import './style.css'

type ExtendFC<P = Record<string, unknown>> = {
  Header: React.FunctionComponent
  Row: React.FunctionComponent<TableRowProps>
  Cell: React.FunctionComponent<TableCellProps>
  Body: React.FunctionComponent<TableBodyProps>
  Footer: React.FunctionComponent<TableFooterProps>
  HeaderCell: React.FunctionComponent<TableHeaderCellProps>
} & React.FC<P>

export type StickyTableProps = {
  height?: string
} & TableProps

export const StickyTable: ExtendFC<StickyTableProps> = props => {
  const { height, style, children, className, ...rest } = props

  const editedClassName = useMemo<string>(
    () => (className ? `${className} sticky_table` : 'sticky_table'),
    [className]
  )

  const wrapperStyle = useMemo<CSSProperties>(
    () => ({
      overflowY: 'scroll',
      height,
    }),
    [height]
  )

  const tableStyle = useMemo<CSSProperties>(
    () =>
      _.assign(
        {},
        {
          border: 'none',
        },
        style
      ),
    [style]
  )

  return (
    <div style={wrapperStyle}>
      <Table className={editedClassName} style={tableStyle} {...rest}>
        {children}
      </Table>
    </div>
  )
}

export default StickyTable

StickyTable.defaultProps = {
  height: '70vh',
}

export type StickyTableHeaderProps = TableHeaderProps

export const StickyTableHeader: React.FC<StickyTableHeaderProps> = props => {
  const { style, fullWidth, children, ...rest } = props

  const customStyle = useMemo<CSSProperties>(
    () =>
      _.assign({}, style, {
        width: fullWidth ? undefined : 'max-content',
      }),
    [style, fullWidth]
  )

  return (
    <Table.Header fullWidth={fullWidth} style={customStyle} {...rest}>
      {children}
    </Table.Header>
  )
}


StickyTable.Header = StickyTableHeader
StickyTable.Header.displayName = 'StickyTable.Header'
StickyTable.Row = Table.Row
StickyTable.Row.displayName = 'StickyTable.Row'
StickyTable.Cell = Table.Cell
StickyTable.Cell.displayName = 'StickyTable.Cell'
StickyTable.Body = Table.Body
StickyTable.Body.displayName = 'StickyTable.Body'
StickyTable.Footer = Table.Footer
StickyTable.Footer.displayName = 'StickyTable.Footer'
StickyTable.HeaderCell = Table.HeaderCell
StickyTable.HeaderCell.displayName = 'StickyTable.HeaderCell'

