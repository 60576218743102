import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { MySwitch } from 'base/router'
import { PrivateRoute, LoginPage } from 'base/authenticate/keycloak'
import { Top } from 'component/page/Top'
import Private from 'component/Private'
import Layout from 'component/layout'

import Error from 'component/page/Error'

import Crud from 'component/page/Crud'
import Attend from 'component/page/Attend'
import Conduct from 'component/page/Conduct'

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Layout.ApplicationHeader />
      <MySwitch>
        <DndProvider backend={HTML5Backend}>
          <Route exact path='/' component={Top} />
          <Route exact path='/login' component={LoginPage} />
          <Route path='/error' component={Error} />

          <PrivateRoute exact path='/private' component={Private} />
          <PrivateRoute path='/crud' component={Crud} />
          <PrivateRoute path='/attend' component={Attend} />
          <PrivateRoute path='/conduct' component={Conduct} />
        </DndProvider>
      </MySwitch>
    </BrowserRouter>
  )
}

export default Router
