import React from 'react'
import { Redirect, Switch as RouterSwitch } from 'react-router-dom'

export const MySwitch: React.FC = props => {
  const { children, ...other } = props

  return (
    <RouterSwitch {...other}>
      {children}
      <Redirect to='/error/404' />
    </RouterSwitch>
  )
}
