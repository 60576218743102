import React, { useEffect, useState } from 'react'
import contextBuilder from './contextBuilder'
import axios, { AxiosInstance } from 'axios'
import _ from 'lodash'
import queryString from 'query-string'
import { useKeycloak } from '@react-keycloak/web'
import { Dimmer, Icon } from 'semantic-ui-react'
import { useToast } from 'base/toast'
import { getConfig } from 'config'

const { api_endpoint } = getConfig()

declare module 'axios' {
  export interface AxiosResponse<T = any> {
    error?: any
  }
}

const filterEmpty = (params: any) =>
  _(params)
    .pickBy(
      (value, _key) => !(value === '' || value === null || value === undefined)
    )
    .value()

const paramsSerializer = (params: any) =>
  queryString.stringify(filterEmpty(params), {
    arrayFormat: 'none',
    skipNull: true,
  })

const createApiAxios = (
  realm?: string,
  handleGetToken?: () => Promise<string | null>,
  handleError?: (content: any) => void
): AxiosInstance => {
  const instance = axios.create({
    baseURL: api_endpoint,
    headers: {
      'Content-Type': 'application/json',
      'accept-language': 'ja,en-US;q=0.9,en;q=0.8',
    },
    responseType: 'json',
    paramsSerializer: paramsSerializer,
    maxBodyLength: 2000,
    maxContentLength: 2000,
  })

  instance.interceptors.request.use(async config => {
    if (realm) {
      config.headers.Realm = realm
    }

    if (!handleGetToken) return config

    const token = await handleGetToken() // will refresh token inside
    if (!token) return config

    config.headers.Authorization = `Bearer ${token}`

    return config
  })

  instance.interceptors.response.use(
    response => response,
    error => {
      const { response, message } = error || {}
      const { data } = response || {}
      const { status, message: api_message, details } = data || {}

      console.debug(
        'axios error',
        JSON.stringify(
          {
            message: error.message,
            uri: error?.config?.url,
            data: error?.config?.data,
            details,
          },
          null,
          2
        )
      )

      handleError &&
        (details
          ? handleError(
              `${_(details)
                .map(row => `${row.message}`)
                .value()
                ?.join('\n')}`
            )
          : handleError(`${api_message || message} ${status || ''}`))

      return Promise.resolve({
        error: error.response ? error.response : error,
      })
    }
  )

  return instance
}

type State =
  | { status: 'Init'; axios: AxiosInstance }
  | { status: 'NotAuthorized'; axios: AxiosInstance }
  | { status: 'Authorized'; axios: AxiosInstance }

type Action =
  | {
      type: 'UNAUTHORIZE'
      realm?: string
      handleError?: (content: any) => void
    }
  | {
      type: 'AUTHORIZE'
      realm?: string
      handleGetToken: () => Promise<string | null>
      handleError: (content: any) => void
    }

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'AUTHORIZE':
      return {
        ...state,
        axios: createApiAxios(
          action.realm,
          action.handleGetToken,
          action.handleError
        ),
        status: 'Authorized',
      }
    case 'UNAUTHORIZE':
      return {
        ...state,
        axios: createApiAxios(action.realm, undefined, action.handleError),
        status: 'NotAuthorized',
      }
  }
}

const initialState: State = { status: 'Init', axios: createApiAxios() }

export const [AxiosStateProvider, useAxios, useAxiosDispatch] = contextBuilder(
  initialState,
  reducer
)

export const AxiosProvider: React.FC = props => (
  <AxiosStateProvider>
    <AxiosFilter {...props} />
  </AxiosStateProvider>
)

const AxiosFilter: React.FC = props => {
  const [loading, setLoading] = useState(true)

  const { keycloak } = useKeycloak()
  const axiosDispatch = useAxiosDispatch()
  const { noticeError } = useToast()

  const handleGetToken = async (): Promise<string | null> => {
    if (!keycloak.authenticated) {
      return null
    }
    await keycloak.updateToken(5)
    return keycloak.token || null
  }

  const onAxiosError = (content: any) =>
    noticeError({
      header: 'ajax error',
      content: content,
    })

  useEffect(() => {
    if (!keycloak.authenticated) {
      axiosDispatch({
        type: 'UNAUTHORIZE',
        realm: undefined,
        handleError: onAxiosError,
      })
      setLoading(false)
      return
    }

    axiosDispatch({
      type: 'AUTHORIZE',
      realm: undefined,
      handleGetToken: handleGetToken,
      handleError: onAxiosError,
    })
    setLoading(false)
  }, [keycloak, axiosDispatch, handleGetToken, setLoading])

  return (
    <>
      {loading ? (
        <Dimmer active page>
          <Icon loading name='spinner' />
          Axios Initializing....
        </Dimmer>
      ) : (
        <>{props.children}</>
      )}
    </>
  )
}
