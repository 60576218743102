import React from 'react'
import Standard from 'base/control/dropdown/Standard'

type Props = React.ComponentProps<typeof Standard>

export const Gender: React.FC<Props> = props => {
  return (
    <Standard
      {...props}
      options={[
        { key: 'MALE', text: 'MALE', value: 'MALE' },
        { key: 'FEMALE', text: 'FEMALE', value: 'FEMALE' },
        { key: 'OTHER', text: 'OTHER', value: 'OTHER' },
      ]}
    />
  )
}

export default Gender
