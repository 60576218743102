import React from 'react'
import { Route } from 'react-router-dom'
import { MySwitch } from 'base/router'

import User from 'component/page/Crud/User'
import Exam from 'component/page/Crud/Exam'

export const BASE_PATH = '/crud'

export const Crud: React.FC = () => {
  return (
    <MySwitch>
      <Route path={`${BASE_PATH}/user`} component={User} />
      <Route path={`${BASE_PATH}/exam`} component={Exam} />
    </MySwitch>
  )
}

export default Crud
