import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { Standard } from './Standard'
import * as Validations from 'base/control/validations'

export type YmProps = React.ComponentProps<typeof Standard>

export const Ym: React.FC<YmProps> = props => {
  const { validate } = props

  const handleEdit = (value: string): string | null => {
    const plain = value.replace(/\u002f/g, '')
    if (!plain.match(/[0-9]{6}/)) return value

    const m = moment(plain, 'YYYYMM')
    if (!m.isValid()) return value

    // valid and format
    return m.format('YYYY/MM')
  }

  return (
    <Standard
      {...props}
      type='tel'
      editValue={handleEdit}
      validate={_.assign({}, validate, { ym: Validations.ym })}
    />
  )
}

export default Ym
