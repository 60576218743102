import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { Grid, Form, Button } from 'semantic-ui-react'
import { useToolbarDispatch } from 'contexts'
import { useAxios } from 'contexts'
import { Selection } from 'types'
import TextArea from 'component/control/textarea'
import Checkbox from 'component/control/checkbox'
import Layout from 'component/layout'

type Props =
  | {
      mode: 'new'
      question_id?: string
      selection_id?: string
      onSave?: () => void
      title?: string
      handleCancel?: () => void
    }
  | {
      mode: 'view' | 'edit' | 'delete'
      question_id: string
      selection_id?: string
      onSave?: () => void
      title?: string
      handleCancel?: () => void
    }

type Inputs = Omit<Selection, 'id'>

const InputForm: React.FC<Props> = props => {
  const { mode, question_id, selection_id, onSave, title, handleCancel } = props

  const SELF_PATH = `/crud/selection`
  const BASE_PATH = `/crud/question/${question_id}/selections`

  const locked = mode === 'view' || mode === 'delete' ? true : false

  const { axios } = useAxios()
  const history = useHistory()
  const toolbarDispatch = useToolbarDispatch()

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm<Inputs>({
    criteriaMode: 'all',
  })

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    toolbarDispatch({
      type: 'SET',
      title: '設問',
    })
  }, [])

  const onSubmit = (model: Inputs) => {
    switch (mode) {
      case 'new':
        postApi(model)
        break
      case 'edit':
        patchApi(model)
        break
      case 'delete':
        deleteApi()
        break
    }
  }

  useEffect(() => {
    const getApi = async () => {
      setLoading(true)
      const { data, error } = await axios.get<Selection>(
        `${SELF_PATH}/${selection_id}`
      )
      setLoading(false)
      if (error) {
        console.log('get error', error)
        return
      }

      const { text, correct, comment } = data || {}
      setValue('text', text, { shouldValidate: true })
      setValue('correct', correct, { shouldValidate: true })
      setValue('comment', comment, { shouldValidate: true })
    }

    if (mode !== 'new') getApi()
  }, [mode, SELF_PATH, setValue])

  const postApi = async (model: Inputs) => {
    setLoading(true)
    const { data, error } = await axios.post<Selection>(
      `${SELF_PATH}`,
      question_id
        ? _.assign({}, model, { question: `/crud/question/${question_id}` })
        : model
    )
    setLoading(false)

    if (error) {
      console.log('post error', error)
      return
    }
    console.log('post data:', data)
    reset(model)
    onSave ? onSave() : history.push(BASE_PATH)
  }

  const patchApi = async (model: Inputs) => {
    setLoading(true)
    const { data, error } = await axios.patch<Selection>(
      `${SELF_PATH}/${selection_id}`,
      model
    )
    setLoading(false)

    if (error) {
      console.log('patch error', error)
      return
    }
    console.log('patch data:', data)
    reset(model)
    onSave ? onSave() : history.push(BASE_PATH)
  }

  const deleteApi = async () => {
    setLoading(true)
    const { data, error } = await axios.delete<Selection>(
      `${SELF_PATH}/${selection_id}`
    )
    setLoading(false)

    if (error) {
      console.log('delete error', error)
      return
    }
    console.log('delete data:', data)
    onSave ? onSave() : history.push(BASE_PATH)
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Layout.Page title={title} loading={loading}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Field
                required
                error={errors.text ? true : false}
                disabled={locked}
              >
                <label>text</label>
                <TextArea.Base.AutoHeight
                  control={control}
                  errors={errors}
                  name='text'
                  placeholder='enter text'
                  keepOpened
                  required
                />
              </Form.Field>

              <Form.Field disabled={locked}>
                <label>correct</label>
                <Checkbox.Base.Buttony
                  control={control}
                  errors={errors}
                  name='correct'
                />
              </Form.Field>

              <Form.Field
                error={errors.comment ? true : false}
                disabled={locked}
              >
                <label>comment</label>
                <TextArea.Base.AutoHeight
                  control={control}
                  errors={errors}
                  name='comment'
                  placeholder='enter commentary'
                  keepOpened
                />
              </Form.Field>

              {mode !== 'view' && (
                <Button
                  type='submit'
                  content='Submit'
                  icon='pencil'
                  primary={isDirty}
                  disabled={!isDirty}
                />
              )}

              {mode === 'new' && (
                <Button
                  as='span'
                  content='Cancel'
                  icon='close'
                  secondary
                  onClick={() => handleCancel && handleCancel()}
                />
              )}

              {selection_id && (
                <Button
                  as='span'
                  icon='trash'
                  content='Delete'
                  floated='right'
                  color='orange'
                  onClick={() => deleteApi()}
                />
              )}
            </Form>
          </Layout.Page>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default InputForm
