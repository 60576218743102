import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import { Menu, Image, Header, Dropdown, Icon, Grid } from 'semantic-ui-react'

import { getConfig } from 'config'
import { useUser, Avatar } from 'base/authenticate'
import MegaMenu from 'component/layout/MegaMenu'

const ApplicationHeader: React.FC = () => {
  return (
    <>
      <Menu
        inverted
        color='black'
        secondary={false}
        size='tiny'
        style={{
          borderRadius: 0,
          marginBottom: 0,
        }}
      >
        <HeroItem />
        <MegaMenu />
        <RightItem />
      </Menu>
    </>
  )
}

type HeroItemProps = Partial<{
  showLogo: boolean
  showEnvName: boolean
}>

export const HeroItem: React.FC<HeroItemProps> = props => {
  const { showLogo, showEnvName } = props

  return (
    <Menu.Item header as={Link} to='/'>
      {showLogo && (
        <Image
          size='tiny'
          src='/images/logo.png'
          style={{
            width: '20px',
            height: '20px',
          }}
        />
      )}
      {showEnvName && (
        <Header
          content='development'
          size='medium'
          style={{
            marginTop: '-2px',
            marginLeft: '5px',
          }}
          color='yellow'
        />
      )}
    </Menu.Item>
  )
}

HeroItem.defaultProps = {
  showLogo: true,
  showEnvName: true,
}

export const RightItem: React.FC = () => {
  const { user } = useUser()

  return (
    <Menu.Menu position='right'>
      {user?.authenticated ? <AuthenticatedMenu /> : <UnAuthenticatedMenu />}
    </Menu.Menu>
  )
}

const UnAuthenticatedMenu: React.FC = () => {
  const { keycloak } = useKeycloak()

  const handleLoginClick = () =>
    (window.location.href = keycloak.createLoginUrl())

  return <Menu.Item name='Sign in' onClick={handleLoginClick} />
}

const AuthenticatedMenu: React.FC = () => {
  const { user } = useUser()
  const { firstName, lastName } = user || {}

  const { keycloak } = useKeycloak()
  const history = useHistory()

  return (
    <Dropdown
      item
      icon=''
      floating
      pointing='top right'
      trigger={
        <>
          <Avatar avatar src='/images/noimage.png' />
          <Grid>
            <Grid.Row only='computer'>
              <Grid.Column>
                <span>{`${lastName} ${firstName}`}</span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      }
    >
      <Dropdown.Menu>
        <Avatar
          size='medium'
          circular
          centered
          src='/images/noimage.png'
          style={{ padding: '30px' }}
        />
        <Dropdown.Item
          content={
            <>
              <Icon name='user' />
              <span>{`${lastName} ${firstName}`}</span>
            </>
          }
        />
        {/*
          <Dropdown.Item
            content={
              <>
                <Icon name='help' />
                <FormattedMessage {...messages.help} />
              </>
            }
          />
          <Dropdown.Item
            content={
              <>
                <Icon name='comments' />
                <FormattedMessage {...messages.feedback} />
              </>
            }
          />
          */}
        <Dropdown.Item
          content={
            <>
              <Icon name='git' />
              <span>{process.env.REACT_APP_VERSION}</span>
            </>
          }
        />
        <Dropdown.Item onClick={() => history.push('/term_of_use')}>
          <Icon name='user' />
          利用規約
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() => {
            keycloak.accountManagement()
          }}
        >
          <Icon name='user' />
          アカウント設定
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            keycloak.logout({
              redirectUri: getConfig().top_url,
            })
          }}
        >
          <Icon name='sign out' />
          サインアウト
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ApplicationHeader
