import React from 'react'
import _ from 'lodash'
import { Controller, Control, FieldErrors } from 'react-hook-form'
import { CheckboxProps, Form, Checkbox } from 'semantic-ui-react'
import ErrorMessageLabel from 'base/control/ErrorLabel'
import message from 'base/message'

type ItemValue = {
  name: string
  value: string | number
}

export type OneProps = {
  control: Control
  errors: FieldErrors
  name: string
  title?: string
  defaultValue?: string | number
  itemValue: ItemValue
  required?: boolean
  validate?: Record<string, (value: unknown) => boolean>
} & CheckboxProps

export const One: React.FC<OneProps> = props => {
  const {
    control,
    errors,
    name,
    defaultValue,
    itemValue,
    required,
    validate,
    ...rest
  } = props

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{
          validate: _.assign({}, validate, {
            atLeastOne: (value: unknown) =>
              !required ? true : value ? true : message('validate.atLeastOne'),
          }),
        }}
        render={({ onChange, onBlur, value: _value, name }) => (
          <Form.Field key={itemValue.value}>
            <Checkbox
              onBlur={onBlur}
              onChange={(_e, { value, checked }) =>
                onChange(checked ? value : null)
              }
              checked={_value === itemValue.value}
              value={itemValue.value}
              label={itemValue.name}
              name={name}
              {...rest}
            />
          </Form.Field>
        )}
      />
      <ErrorMessageLabel errors={errors} name={name} />
    </>
  )
}

export default One
