import React from 'react'

import { NumberBase } from './NumberBase'

export type IntZeroPaddingProps = React.ComponentProps<typeof NumberBase>

export const IntZeroPadding: React.FC<IntZeroPaddingProps> = props => (
  <NumberBase {...props} zeroPadding />
)

export default IntZeroPadding
