import moment, { Moment } from 'moment'

export const parseAsMoment = (dateTimeStr: string): Moment =>
  moment.utc(dateTimeStr, 'YYYY-MM-DDTHH:mm:00Z', 'ja').utcOffset(9)

export const toUtcIso8601str = (momentInstance: Moment): string =>
  momentInstance.clone().utc().format('YYYY-MM-DDTHH:mm:00Z')

export const ymdstrToDate = (ymd: string | null | undefined): Date | null =>
  moment(ymd, 'YYYY/MM/DD').isValid()
    ? moment(ymd, 'YYYY/MM/DD').toDate()
    : null

export const dateToYmd = (date: Date): string | null =>
  moment(date).isValid() ? moment(date).format('YYYY/MM/DD') : null

export default {
  parseAsMoment,
  toUtcIso8601str,
  ymdstrToDate,
  dateToYmd,
}
