import React from 'react'
import _ from 'lodash'

import { Standard } from './Standard'
import * as Validations from 'base/control/validations'

export type NumberBaseProps = {
  zeroPadding?: boolean
  signed?: boolean
  decimal?: boolean
} & React.ComponentProps<typeof Standard>

export const NumberBase: React.FC<NumberBaseProps> = props => {
  const { validate, zeroPadding, signed, decimal, ...rest } = props

  const handleEdit = (value: string): string | null => {
    return value
  }

  return (
    <Standard
      type='number'
      {...rest}
      editValue={handleEdit}
      validate={_.assign(
        {},
        validate,
        zeroPadding
          ? signed
            ? decimal
              ? {
                  signedNumericZeroPadding:
                    Validations.signedNumericZeroPadding,
                }
              : { signedIntZeroPadding: Validations.signedIntZeroPadding }
            : decimal
            ? { numericZeroPadding: Validations.numericZeroPadding }
            : { intZeroPadding: Validations.intZeroPadding }
          : signed
          ? decimal
            ? { signedNumeric: Validations.signedNumeric }
            : { signedInt: Validations.signedInt }
          : decimal
          ? { numeric: Validations.numeric }
          : { int: Validations.int }
      )}
    />
  )
}

export default NumberBase
