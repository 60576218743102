import React, { useState } from 'react'
import { Icon, List } from 'semantic-ui-react'
import { Link, useHistory } from 'react-router-dom'

import { MenuItem } from '.'
import { MenuBlockProps } from './MenuBlock'
import MenuItemList from './MenuItemList'

export type MenuItemPanelProps = {
  item: MenuItem
  subMenu: boolean
} & Omit<MenuBlockProps, 'menuItems'>

export const MenuItemPanel: React.FC<MenuItemPanelProps> = props => {
  const {
    handleMenuSelected,
    handleClickFavorites,
    isFavorited,
    subMenu,
    item,
  } = props

  const history = useHistory()

  const [isHovered, setIsHovered] = useState<boolean>(false)

  return (
    <List.Item
      style={{
        paddingLeft: subMenu && '10px',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {item.url && (isFavorited(item) || isHovered) && (
        <List.Content floated='right'>
          <Icon
            name='heart'
            size='large'
            color={isFavorited(item) ? 'pink' : undefined}
            onClick={() => {
              handleClickFavorites(item)
            }}
          />
        </List.Content>
      )}

      <List.Icon
        name={item.icon || 'asexual'}
        size={subMenu ? 'small' : 'large'}
        verticalAlign='middle'
      />

      <List.Content
        onClick={() => {
          if (!item.url) return
          handleMenuSelected(item)
          history.push(item.url)
        }}
      >
        {item.url ? (
          <List.Header
            content={item.title}
            as={Link}
            to={item.url}
            onClick={() => {
              handleMenuSelected(item)
            }}
          />
        ) : (
          <List.Header content={item.title} />
        )}
        <List.Description content={item.description} />
      </List.Content>

      {item.subMenus && (
        <MenuItemList {...props} menuItems={item.subMenus} subMenu />
      )}
    </List.Item>
  )
}

export default MenuItemPanel
