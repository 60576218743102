import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { Standard } from './Standard'
import * as Validations from 'base/control/validations'

export type YmdhmProps = React.ComponentProps<typeof Standard>

export const Ymdhm: React.FC<YmdhmProps> = props => {
  const { validate } = props

  const handleEdit = (value: string): string | null => {
    const plain = value.replace(/[\u002f\u003a\u0020]/g, '')
    if (!plain.match(/[0-9]{12}/)) return value

    const m = moment(plain, 'YYYYMMDDHHmm')
    if (!m.isValid()) return value

    // valid and format
    return m.format('YYYY/MM/DD HH:mm')
  }

  return (
    <Standard
      {...props}
      type='tel'
      editValue={handleEdit}
      validate={_.assign({}, validate, { ymdhm: Validations.ymdhm })}
    />
  )
}

export default Ymdhm
