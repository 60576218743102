import React from 'react'
import contextBuilder from 'base/util/contextBuilder'
import { UserType } from 'base/authenticate'

type State =
  | { status: 'Init'; user: null }
  | { status: 'Loading'; user: null }
  | { status: 'Unauthenticated'; user: null }
  | { status: 'Authenticated'; user: UserType }

type Action =
  | { type: 'START_LOGIN' }
  | { type: 'FAILURE_LOGIN' }
  | { type: 'COMPLETE_LOGIN'; user: UserType }
  | { type: 'COMPLETE_LOGOUT' }

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'START_LOGIN':
      return {
        ...state,
        status: 'Loading',
        user: null,
      }
    case 'FAILURE_LOGIN':
      return {
        ...state,
        status: 'Unauthenticated',
        user: null,
      }
    case 'COMPLETE_LOGIN':
      return {
        ...state,
        status: 'Authenticated',
        user: action.user,
      }
    case 'COMPLETE_LOGOUT':
      return {
        ...state,
        status: 'Unauthenticated',
        user: null,
      }
  }
}

const initialState: State = { status: 'Init', user: null }

export const [UserProvider, useUser, useUserDispatch] = contextBuilder(
  initialState,
  reducer
)
