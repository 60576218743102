import React from 'react'
import _ from 'lodash'
import { Label, Dropdown, DropdownProps } from 'semantic-ui-react'

const limitOptions = [
  { key: '0', value: 10, text: '10' },
  { key: '1', value: 25, text: '25' },
  { key: '2', value: 50, text: '50' },
  { key: '3', value: 100, text: '100' },
  { key: '4', value: 1000, text: '1000' },
  { key: '5', value: Number.MAX_SAFE_INTEGER, text: '全' },
]

type PagelimitSelectorProps = {
  limit: number
  onLimitChange: (value: number) => void
}

export const PageLimitSelector: React.FC<PagelimitSelectorProps> = props => {
  const { limit, onLimitChange } = props

  const handleOnChange = (_e: any, data: DropdownProps) => {
    const { value } = data
    onLimitChange(_.toInteger(value))
  }

  return (
    <>
      <Label size='large'>
        <Dropdown
          options={limitOptions}
          defaultValue={limit}
          onChange={handleOnChange}
        />
        <span>件表示</span>
      </Label>
    </>
  )
}

export default PageLimitSelector
