import React, { useMemo } from 'react'
import _ from 'lodash'
import { ErrorMessage } from '@hookform/error-message'
import { Label, List } from 'semantic-ui-react'

type ErrorMessageProps = {
  errors: Record<string, any>
  name: string
}

export const ErrorMessageLabel: React.FC<ErrorMessageProps> = props => {
  const { errors, name } = props

  const isArrayInput = useMemo(
    () => (name.split('[').length > 1 ? true : false),
    [name]
  )

  return errors ? (
    isArrayInput ? (
      <ArrayErrorMessage {...props} />
    ) : (
      <SimpleErrorMessage {...props} />
    )
  ) : null
}

export default ErrorMessageLabel

const SimpleErrorMessage: React.FC<ErrorMessageProps> = props => {
  const { errors, name } = props

  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ messages }) => (
        <Label
          basic
          color='red'
          pointing
          content={
            <List bulleted>
              {messages
                ? _.entries(messages).map(([type, message]) => (
                    <List.Item key={type}>{message}</List.Item>
                  ))
                : null}
            </List>
          }
        />
      )}
    />
  )
}

const ArrayErrorMessage: React.FC<ErrorMessageProps> = props => {
  const { errors, name } = props

  const simpleName = name.split('[')[0]

  const filteredErrors = _(errors[simpleName])
    .filter(error => error?.ref?.name === name)
    .value()

  return (
    <>
      {filteredErrors.length > 0 ? (
        <Label
          basic
          color='red'
          pointing
          style={{ display: 'block' }}
          content={
            <List bulleted>
              {_(filteredErrors)
                .map(error => (
                  <List.Item key={error.type}>{error.message}</List.Item>
                ))
                .value()}
            </List>
          }
        />
      ) : null}
    </>
  )
}
