import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { Standard } from './Standard'
import * as Validations from 'base/control/validations'

export type YmdProps = React.ComponentProps<typeof Standard>

export const Ymd: React.FC<YmdProps> = props => {
  const { validate } = props

  const handleEdit = (value: string): string | null => {
    const plain = value.replace(/\//g, '')
    if (!plain.match(/[0-9]{8}/)) return value

    const m = moment(plain, 'YYYYMMDD')
    if (!m.isValid()) return value

    // valid and format
    return m.format('YYYY/MM/DD')
  }

  return (
    <Standard
      {...props}
      type='tel'
      editValue={handleEdit}
      validate={_.assign({}, validate, { ymd: Validations.ymd })}
    />
  )
}

export default Ymd
