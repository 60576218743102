import React from 'react'
import { Route, useParams } from 'react-router-dom'
import { MySwitch } from 'base/router'
import { ToolbarProvider } from 'contexts'
import List from './List'
import InputForm from './InputForm'
import Questions from './Questions'

export const BASE_PATH = '/crud/exam/:exam_id/plactices'

export const Plactices: React.FC = () => {
  return (
    <MySwitch>
      <Route exact path={`${BASE_PATH}`} component={Top} />
      <Route exact path={`${BASE_PATH}/new`} component={New} />
      <Route exact path={`${BASE_PATH}/:plactice_id/view`} component={View} />
      <Route exact path={`${BASE_PATH}/:plactice_id/edit`} component={Edit} />
      <Route
        exact
        path={`${BASE_PATH}/:plactice_id/delete`}
        component={Delete}
      />
      <Route
        path={`${BASE_PATH}/:plactice_id/questions`}
        component={Questions}
      />
    </MySwitch>
  )
}

export default Plactices

type PathParams = {
  exam_id: string
  plactice_id: string
}

export const Top: React.FC = () => {
  const params = useParams<PathParams>()
  const { exam_id } = params

  return (
    <ToolbarProvider>
      <List exam_id={exam_id} />
    </ToolbarProvider>
  )
}

export const New: React.FC = () => {
  const params = useParams<PathParams>()
  const { exam_id } = params || {}

  return (
    <ToolbarProvider>
      <InputForm mode='new' exam_id={exam_id} title='練習問題' />
    </ToolbarProvider>
  )
}

export const View: React.FC = () => {
  const params = useParams<PathParams>()
  const { exam_id, plactice_id } = params || {}

  return (
    <ToolbarProvider>
      <InputForm
        mode='view'
        exam_id={exam_id}
        title='練習問題'
        plactice_id={plactice_id}
      />
    </ToolbarProvider>
  )
}

export const Edit: React.FC = () => {
  const params = useParams<PathParams>()
  const { exam_id, plactice_id } = params || {}

  return (
    <ToolbarProvider>
      <InputForm
        mode='edit'
        exam_id={exam_id}
        title='練習問題'
        plactice_id={plactice_id}
      />
    </ToolbarProvider>
  )
}

export const Delete: React.FC = () => {
  const params = useParams<PathParams>()
  const { exam_id, plactice_id } = params || {}

  return (
    <ToolbarProvider>
      <InputForm
        mode='delete'
        exam_id={exam_id}
        title='練習問題'
        plactice_id={plactice_id}
      />
    </ToolbarProvider>
  )
}
