import React from 'react'
import { Controller, Control } from 'react-hook-form'
import { ButtonProps, Button } from 'semantic-ui-react'

export type ButtonyProps = {
  control: Control
  name: string
  title?: string
  defaultValue?: boolean
} & ButtonProps

export const Buttony: React.FC<ButtonyProps> = props => {
  const { control, name, defaultValue, ...rest } = props

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || false}
        render={({ onChange, onBlur, value, name }) => (
          <Button
            as='span'
            onBlur={onBlur}
            onClick={() => onChange(!value)}
            name={name}
            icon={value ? 'check' : 'minus'}
            positive={value ? true : false}
            {...rest}
          />
        )}
      />
    </>
  )
}

export default Buttony
