import React from 'react'
import { List } from 'semantic-ui-react'
import _ from 'lodash'
import { Link } from 'react-router-dom'

import { MenuItems, LeftBlockProps } from '.'

export type MiniMenuProps = {
  source: MenuItems
  maxItemCount: number
} & Pick<LeftBlockProps, 'handleMenuSelected'>

export const MiniMenuPanel: React.FC<MiniMenuProps> = props => {
  const { source, maxItemCount, handleMenuSelected } = props

  return (
    <List inverted divided relaxed selection>
      {_(source)
        .orderBy(['selectedDate'], ['desc'])
        .take(maxItemCount)
        .map((history, i) => (
          <List.Item key={i}>
            <List.Icon
              name={history.icon || 'asexual'}
              size='large'
              verticalAlign='middle'
            />
            <List.Content>
              <List.Header
                content={history.title}
                as={Link}
                to={history.url}
                onClick={() => {
                  handleMenuSelected(history)
                }}
              />
              <List.Description content={history.description} />
            </List.Content>
          </List.Item>
        ))
        .value()}
    </List>
  )
}

export default MiniMenuPanel
