import React, { PropsWithChildren, ReactElement, useMemo } from 'react'
import _ from 'lodash'
import { Dropdown, InputProps } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import { StandardProps } from './Standard'
import Paging from './Paging'
import Textbox from 'base/control/textbox'

export type SearchProps<_T> = StandardProps

type SearchBoxInputs = {
  searchWord: string
}

export const Search = <T,>(
  props: PropsWithChildren<SearchProps<T>>
): ReactElement<any, any> | null => {
  const { options, ...rest } = props
  const { control, setValue, watch, errors } = useForm<SearchBoxInputs>()
  const searchWord = watch('searchWord')

  const handleOnChange = (_e: any, data: InputProps) => {
    setValue('searchWord', data.value)
  }

  const filteredOptions = useMemo(
    () =>
      _(options)
        .filter(option =>
          _.find(option, value => _.includes(value, searchWord))
        )
        .value(),
    [options, searchWord]
  )

  return (
    <>
      <Paging
        {...rest}
        options={filteredOptions}
        SearchBlock={
          <>
            <Dropdown.Header
              content='Search'
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
            />
            <Textbox.Standard
              control={control}
              errors={errors}
              name='searchWord'
              icon='search'
              iconPosition='left'
              placeholder='filter keyword...'
              defaultValue={''}
              onChange={handleOnChange}
              autoComplete='off'
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
            />
          </>
        }
      />
    </>
  )
}

export default Search
