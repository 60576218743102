import React from 'react'
import { useRouteMatch, useParams, useHistory } from 'react-router-dom'
import _ from 'lodash'
import routes from 'config/routes.json'
import { Step, Icon } from 'semantic-ui-react'
import './breadCrumb.css'

type RouteType = {
  path: string
  name: string
  icon?: any
  noLink?: boolean
}

type RoutesType = RouteType[]

type Params = {
  [key: string]: any
}

const replaceByParam = (params: Params, origin: string): string =>
  Object.keys(params).length
    ? Object.keys(params).reduce(
        (origin, param) => origin.replace(`:${param}`, params[param]),
        origin
      )
    : origin

const generateCrumbs = (
  routes: RoutesType,
  matchPath: string,
  params: Params
): RoutesType => {
  const crumbs = routes
    .filter(({ path, name }) => matchPath.includes(path) && !_.isEmpty(name))
    .map(({ path, name, icon, noLink }) => ({
      path: replaceByParam(params, path),
      name: replaceByParam(params, name),
      icon,
      noLink,
    }))

  return crumbs
}

const BreadCrumb: React.FC = () => {
  const params = useParams()
  const match = useRouteMatch()
  const history = useHistory()
  const { path } = match

  const crumbs = generateCrumbs(routes, path, params)
  const links = _.dropRight(crumbs)
  const active = _.last(crumbs)

  return (
    <>
      <Step.Group
        className='my-breadcrumb'
        size='mini'
        unstackable
        style={{
          marginTop: 0,
        }}
      >
        {_(links)
          .map((route: RouteType, i: number) => (
            <Step
              key={i}
              link={!route.noLink}
              onClick={() => !route.noLink && history.push(route.path)}
            >
              {route.icon && (
                <Icon
                  name={route.icon}
                  size='mini'
                  style={{ fontSize: '1em' }}
                />
              )}
              <Step.Content>
                <Step.Title>{route.name}</Step.Title>
              </Step.Content>
            </Step>
          ))
          .value()}
        {active && <Step title={active.name} active />}
      </Step.Group>
    </>
  )
}

export default BreadCrumb
