import React from 'react'

import { NumberBase } from './NumberBase'

export type SignedNumericZeroPaddingProps = React.ComponentProps<typeof NumberBase>

export const SignedNumericZeroPadding: React.FC<SignedNumericZeroPaddingProps> = props => (
  <NumberBase {...props} decimal signed zeroPadding />
)

export default SignedNumericZeroPadding
