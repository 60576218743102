import React, { useState, useMemo } from 'react'
import { Search, SearchProps, SearchResultData, Input } from 'semantic-ui-react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { useUser } from 'base/authenticate'
import { RightBlockProps, isAllowedByRole, menuSource } from '.'

type SearchMenuProps = Omit<
  RightBlockProps,
  'handleClickFavorites' | 'isFavorited'
>

export const SearchMenu: React.FC<SearchMenuProps> = props => {
  const { handleMenuSelected } = props
  const { user } = useUser()
  const { roles } = user || {}
  const history = useHistory()

  const [value, setValue] = useState<string | undefined>()

  const searchSource = useMemo(
    () =>
      _(menuSource)
        .filter(item => isAllowedByRole(item.roles, roles))
        .map(parent =>
          _(parent.subMenus)
            .filter(item => isAllowedByRole(item.roles, roles))
            .filter(item =>
              value ? _.find(item, values => _.includes(values, value)) : true
            )
            .map(submenu => ({
              group: parent.title,
              ...submenu,
            }))
            .value()
        )
        .flatten()
        .groupBy('group')
        .mapValues((value, key) => ({
          name: key,
          results: value,
        }))
        .value(),
    [value, roles]
  )

  const handleSearchChange = (
    _e: React.MouseEvent<HTMLElement>,
    data: SearchProps
  ) => {
    const { value } = data
    setValue(value)
  }

  const handleSelect = (
    _e: React.MouseEvent<HTMLDivElement>,
    data: SearchResultData
  ) => {
    const { result } = data
    const { url } = result
    if (!url) return
    handleMenuSelected(result)
    history.push(url)
  }

  return (
    <Search
      fluid
      category
      results={searchSource}
      onSearchChange={handleSearchChange}
      onResultSelect={handleSelect}
      value={value}
      input={<Input fluid />}
    />
  )
}

export default SearchMenu
