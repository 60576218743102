import React from 'react'
import _ from 'lodash'
import moment from 'moment'

import { Standard } from './Standard'
import * as Validations from 'base/control/validations'

export type YearProps = React.ComponentProps<typeof Standard>

export const Year: React.FC<YearProps> = props => {
  const { validate } = props

  const handleEdit = (value: string): string | null => {
    if (!value.match(/[0-9]{4}/)) return value

    const m = moment(value, 'YYYY')
    if (!m.isValid()) return value

    // valid and format
    return m.format('YYYY')
  }

  return (
    <Standard
      {...props}
      type='tel'
      editValue={handleEdit}
      validate={_.assign({}, validate, { year: Validations.year })}
    />
  )
}

export default Year
