import React, { useState } from 'react'
import {
  Menu,
  Icon,
  Portal,
  Segment,
  Grid,
  Label,
  Header,
} from 'semantic-ui-react'
import moment from 'moment'
import _ from 'lodash'
import { useUser } from 'base/authenticate'
import { useLocalStorage } from 'base/hook'
import configMenu from 'config/menu.json'
import MiniMenuPanel from './MiniMenuPanel'
import SearchMenu from './SearchMenu'
import MenuBlock from './MenuBlock'
import './style.css'

export type MenuItem = {
  title: string
  description?: string
  icon?: any
  url?: string
  roles?: string[] | null
  subMenus?: MenuItems
  group?: string
  selectedDate?: number
}

export type MenuItems = MenuItem[] | null

export const menuSource: MenuItems = configMenu

export const MegaMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { user } = useUser()

  const [menuItemHistories, setMenuItemHistories] = useLocalStorage<MenuItems>({
    dbkey: 'menu',
    key: 'histories',
    initial: [],
  })

  const [menuItemFavorites, setMenuItemFavorites] = useLocalStorage<MenuItems>({
    dbkey: 'menu',
    key: 'favorites',
    initial: [],
  })

  const handleMenuOpen = () => setIsOpen(true)
  const handleMenuClose = () => setIsOpen(false)
  const handleMenuSelected = (item: MenuItem) => {
    const stagingItem = { ...item, selectedDate: moment().unix() }

    setMenuItemHistories(histories =>
      _.unionBy([stagingItem], histories, 'title')
    )

    setIsOpen(false)
  }
  const handleClickFavorites = (item: MenuItem) => {
    isFavorited(item)
      ? setMenuItemFavorites(favorites =>
          _(favorites)
            .filter(favorite => !_.isEqual(favorite, item))
            .value()
        )
      : setMenuItemFavorites(favorites => _.unionBy([item], favorites, 'title'))
  }

  const isFavorited = (item: MenuItem): boolean =>
    _.some(menuItemFavorites, item)

  if (!user?.authenticated) return <></>

  return (
    <>
      <Menu.Item active={isOpen} onClick={handleMenuOpen} disabled={isOpen}>
        Menu
        <Icon name={isOpen ? 'caret up' : 'caret down'} />
      </Menu.Item>

      <Portal open={isOpen} onClose={handleMenuClose}>
        <div
          style={{
            position: 'absolute',
            top: '4.2rem',
            width: '100%',
            zIndex: 9999,
          }}
          className='megaMenu'
        >
          <Segment
            inverted
            color='black'
            style={{
              position: 'relative',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '90%',
              padding: 0,
            }}
          >
            <Grid
              stackable
              style={{
                borderTopColor: 'gray',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                marginLeft: 0,
                marginRight: 0,
              }}
            >
              <Grid.Column
                width={4}
                style={{
                  borderRightColor: 'gray',
                  borderRightStyle: 'solid',
                  borderRightWidth: '1px',
                }}
              >
                <Segment inverted color='black'>
                  <LeftBlock
                    histories={menuItemHistories}
                    favorites={menuItemFavorites}
                    handleMenuSelected={handleMenuSelected}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column width={12}>
                <Segment inverted color='black'>
                  <RightBlock
                    handleMenuClose={handleMenuClose}
                    handleMenuSelected={handleMenuSelected}
                    handleClickFavorites={handleClickFavorites}
                    isFavorited={isFavorited}
                  />
                </Segment>
              </Grid.Column>
            </Grid>
            <Label
              corner='right'
              icon='close'
              color='blue'
              onClick={handleMenuClose}
            />
          </Segment>
        </div>
      </Portal>
    </>
  )
}

export default MegaMenu

export const roleFilter = (
  items: MenuItems,
  roles?: string[] | null
): MenuItems | null =>
  !roles
    ? null
    : _(items)
        .filter(item => isAllowedByRole(item.roles, roles))
        .value()

export const isAllowedByRole = (
  requiredRoles: string[] | null | undefined,
  havingRoles: string[] | null | undefined
): boolean =>
  _.isArray(requiredRoles) && requiredRoles.length > 0
    ? _.find(havingRoles, role => _.includes(requiredRoles, role))
      ? true
      : false
    : true

export type LeftBlockProps = {
  histories: MenuItems
  favorites: MenuItems
  handleMenuSelected: (item: MenuItem) => void
}

const LeftBlock: React.FC<LeftBlockProps> = props => {
  const { histories, favorites, handleMenuSelected } = props

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header as='h1' inverted>
            <Icon name='history' color='orange' />
            <Header.Content>Histories</Header.Content>
          </Header>
          <MiniMenuPanel
            source={histories}
            handleMenuSelected={handleMenuSelected}
            maxItemCount={5}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Header as='h1' inverted>
            <Icon name='heart' color='pink' />
            <Header.Content>Favorites</Header.Content>
          </Header>
          <MiniMenuPanel
            source={favorites}
            handleMenuSelected={handleMenuSelected}
            maxItemCount={999}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export type RightBlockProps = {
  handleMenuClose: () => void
  handleMenuSelected: (item: MenuItem) => void
  handleClickFavorites: (item: MenuItem) => void
  isFavorited: (item: MenuItem) => boolean
}

const RightBlock: React.FC<RightBlockProps> = props => {
  const {
    handleMenuClose,
    handleMenuSelected,
    handleClickFavorites,
    isFavorited,
  } = props

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header as='h1' inverted>
            <Icon name='globe' color='blue' />
            <Header.Content>Services</Header.Content>
          </Header>
          <SearchMenu
            handleMenuClose={handleMenuClose}
            handleMenuSelected={handleMenuSelected}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Grid stackable>
            <Grid.Row columns={3}>
              <MenuBlock
                handleMenuClose={handleMenuClose}
                handleMenuSelected={handleMenuSelected}
                handleClickFavorites={handleClickFavorites}
                isFavorited={isFavorited}
                menuItems={menuSource}
              />
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
