import React from 'react'
import _ from 'lodash'

import { Standard } from './Standard'
import * as Validations from 'base/control/validations'

export type MoneyProps = React.ComponentProps<typeof Standard>

export const Money: React.FC<MoneyProps> = props => {
  const { validate } = props

  const handleEdit = (value: string): string | null => {
    const plain = value.replace(/,/g, '')
    if (!plain.match(/^[-]?([1-9]\d*|0)$/)) return value

    return parseInt(plain.toString().replace(/,/g, ''))
      .toString()
      .replace(/(\d)(?=(\d{3})+$)/g, '$1,')
  }

  return (
    <Standard
      {...props}
      type='tel'
      editValue={handleEdit}
      validate={_.assign({}, validate, { money: Validations.money })}
    />
  )
}

export default Money
