import React, { useState, useEffect, useMemo } from 'react'
import {
  Grid,
  Card,
  Header,
  Button,
  Icon,
  Segment,
  Dimmer,
  Loader,
  SemanticCOLORS,
  SemanticICONS,
} from 'semantic-ui-react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { useAxios } from 'contexts'
import LeftMenu from './LeftMenu'
import { SampleAreaChart, SampleRadarChart } from './Chart'
import { MyCalendar } from './Calendar'
import { AnalyzeOverview } from 'types'

import './style.css'

export const DashBoard: React.FC = () => {
  return (
    <Grid>
      <Grid.Row only='computer tablet'>
        <Grid.Column computer={3} tablet={4}>
          <LeftMenu />
        </Grid.Column>
        <Grid.Column computer={13} tablet={12}>
          <DashBoardMenu />
          <DashBoardTop />
          <DashBoardCharts />
          <DashBoardCalendar />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row only='mobile'>
        <Grid.Column width={16}>
          <DashBoardMenu />
          <DashBoardTop />
          <DashBoardCharts />
          <DashBoardCalendar />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default DashBoard

const DashBoardMenu: React.FC = () => {
  const history = useHistory()

  return (
    <Grid stackable padded>
      <Grid.Column
        computer={8}
        tablet={8}
        mobile={16}
        onClick={() => history.push('/attend')}
      >
        <Segment placeholder>
          <Header icon>
            <Icon name='pencil' />
          </Header>
          <Button primary size='big' content='模擬試験に挑戦' />
        </Segment>
      </Grid.Column>
      <Grid.Column
        computer={8}
        tablet={8}
        mobile={16}
        onClick={() => history.push('/crud/exam')}
      >
        <Segment placeholder>
          <Header icon>
            <Icon name='add' />
          </Header>
          <Button primary size='big' content='試験問題を作る' />
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

const DashBoardTop: React.FC = () => {
  const { axios } = useAxios()

  const [loading, setLoading] = useState(false)
  const [analyzeList, setAnalyzeList] = useState<AnalyzeOverview[]>([])

  useEffect(() => {
    const forward = async () => {
      setLoading(true)
      const { data, error } = await axios.get<AnalyzeOverview[]>(
        '/rest/analyze/me'
      )
      setLoading(false)
      if (error) return

      setAnalyzeList(data)
    }

    forward()
  }, [])

  const attendSum = useMemo(() => analyzeList.length, [analyzeList])

  const spendSecondSum = useMemo(() => _(analyzeList).sumBy('spend_second'), [
    analyzeList,
  ])

  return (
    <>
      <Grid stackable padded>
        <Grid.Column computer={4} tablet={8} mobile={8}>
          <DashCard
            color='red'
            icon='pencil'
            title={attendSum.toString()}
            meta='Attends'
            description='模擬試験を受講した回数'
            loading={loading}
          />
        </Grid.Column>
        <Grid.Column computer={4} tablet={8} mobile={8}>
          <DashCard
            color='green'
            icon='clock'
            title={
              spendSecondSum
                ? `${Math.floor(
                    (spendSecondSum % (24 * 60 * 60)) / (60 * 60)
                  )}h${Math.floor(
                    ((spendSecondSum % (24 * 60 * 60)) % (60 * 60)) / 60
                  )}m${((spendSecondSum % (24 * 60 * 60)) % (60 * 60)) % 60}s`
                : '0'
            }
            meta='Time'
            description='あなたの勉強時間'
            loading={loading}
          />
        </Grid.Column>
        <Grid.Column computer={4} tablet={8} mobile={8}>
          <DashCard
            color='purple'
            icon='trophy'
            title='0'
            meta='Examps'
            description='あなたが合格した資格'
            loading={loading}
          />
        </Grid.Column>
        <Grid.Column computer={4} tablet={8} mobile={8}>
          <DashCard
            color='teal'
            icon='briefcase'
            title='0'
            meta='Questions'
            description='あなたが作った設問の数'
            loading={loading}
          />
        </Grid.Column>
      </Grid>
    </>
  )
}

type DashCardProps = {
  color: SemanticCOLORS
  icon: SemanticICONS
  title: string
  meta: string
  description: string
  loading?: boolean
}

const DashCard: React.FC<DashCardProps> = props => {
  const { color, icon, title, meta, description, loading } = props

  return (
    <Card fluid color={color}>
      {loading && (
        <Dimmer active inverted>
          <Loader icon='spinner' active />
        </Dimmer>
      )}

      <Card.Content>
        <Card.Header>
          <Header
            floated='right'
            color={color}
            content={<Icon name={icon} />}
          />
        </Card.Header>
        <Card.Header>
          <Header color={color} content={title} />
        </Card.Header>
        <Card.Meta content={meta} />
        <Card.Description content={description} />
      </Card.Content>
      <Card.Content extra>
        <Button color={color} fluid content='More Info' />
      </Card.Content>
    </Card>
  )
}

const DashBoardCharts: React.FC = () => {
  return (
    <Grid stackable padded>
      <Grid.Column computer={8} tablet={8} mobile={16}>
        <Header as='h3' attached='top' block>
          Views
        </Header>
        <Segment attached style={{ height: 300 }}>
          <SampleAreaChart />
        </Segment>
      </Grid.Column>

      <Grid.Column computer={8} tablet={8} mobile={16}>
        <Header as='h3' attached='top' block>
          KPIs
        </Header>
        <Segment attached style={{ height: 300 }}>
          <SampleRadarChart />
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

const DashBoardCalendar: React.FC = () => {
  return (
    <Grid stackable padded>
      <Grid.Column width={16}>
        <Header as='h3' attached='top' block>
          Schedule
        </Header>
        <Segment attached>
          <MyCalendar />
        </Segment>
      </Grid.Column>
    </Grid>
  )
}
