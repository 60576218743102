import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import contextBuilder from './contextBuilder'
import { Menu, Visibility, Header } from 'semantic-ui-react'
import { HeroItem, RightItem } from 'component/layout/ApplicationHeader'
import BreadCrumb from 'base/piece/BreadCrumb'

type State = {
  title?: string
  menuItems?: React.ReactNode
}

type Action = {
  type: 'SET'
  title?: string
  menuItems?: React.ReactNode
}

const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'SET':
      return {
        ...state,
        title: action.title,
        menuItems: action.menuItems,
      }
  }
}

const initialState: State = { title: 'No Title' }

export const [
  ToolbarStateProvider,
  useToolbar,
  useToolbarDispatch,
] = contextBuilder(initialState, reducer)

export const ToolbarProvider: React.FC = props => (
  <ToolbarStateProvider>
    <Toolbar />
    {props.children}
  </ToolbarStateProvider>
)

const Toolbar: React.FC = () => {
  const toolbar = useToolbar()
  const { title, menuItems } = toolbar
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  const [onTop, setOnTop] = useState<boolean>(true)

  if (isTabletOrMobile) return <></>

  return (
    <>
      <Visibility
        once={false}
        onBottomPassed={() => setOnTop(false)}
        onBottomPassedReverse={() => setOnTop(true)}
      >
        <div style={{ marginBottom: '20px' }}>
          <Menu
            fixed={onTop ? undefined : 'top'}
            secondary={false}
            size='tiny'
            borderless
            style={{
              borderRadius: 0,
              zIndex: 9999,
              marginBottom: 0,
            }}
          >
            {!onTop && <HeroItem showEnvName={false} />}

            {/* <Menu.Item style={{ padding: '3px' }}>
            <BreadCrumb />
          </Menu.Item> */}

            {title && (
              <Menu.Item header>
                <Header size='medium'>{title}</Header>
              </Menu.Item>
            )}

            {menuItems && menuItems}

            {!onTop && <RightItem />}
          </Menu>
          <BreadCrumb />
        </div>
      </Visibility>
    </>
  )
}
