import React from 'react'

import { NumberBase } from './NumberBase'

export type SignedIntZeroPaddingProps = React.ComponentProps<typeof NumberBase>

export const SignedIntZeroPadding: React.FC<SignedIntZeroPaddingProps> = props => (
  <NumberBase {...props} signed zeroPadding />
)

export default SignedIntZeroPadding
