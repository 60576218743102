import messages from './message.json'

export const injectVariables = (template: string, replacement: Record<string, any>): string => {
  const entries = Object.entries(replacement)
  const result = entries.reduce((output, entry) => {
    const [key, value] = entry
    const regex = new RegExp(`\\$\{${key}}`, 'g')
    return output.replace( regex, value.toString() )
  }, template)
  return result
}

type MessageItem = {
  [key: string]: string
}

const message_source: MessageItem = messages

export const message = (key: string, options?: Record<string, unknown>): string => {
  const template = message_source[key]
  if (!template) return `no message defined for key ${key}`
  if (!options) return template
  return injectVariables(template, options)
}

export default message
