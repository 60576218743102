import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import {
  Container,
  Segment,
  Card,
  Icon,
  Loader,
  Button,
} from 'semantic-ui-react'
import { useAxios, useToolbarDispatch } from 'contexts'
import { HalResource, Plactice, Question, Attend } from 'types'
import Layout from 'component/layout'

type Props = {
  exam_id: string
}

export const PlacticeList: React.FC<Props> = props => {
  const { exam_id } = props

  const toolbarDispatch = useToolbarDispatch()
  const { axios } = useAxios()
  const [loading, setLoading] = useState(false)
  const [placticeList, setPlacticeList] = useState<Plactice[]>([])

  useEffect(() => {
    toolbarDispatch({
      type: 'SET',
      title: '模擬試験の選択',
    })
  }, [])

  useEffect(() => {
    const forward = async () => {
      setLoading(true)
      const { data, error } = await axios.get<HalResource<Plactice>>(
        `/crud/exam/${exam_id}/plactices`
      )
      setLoading(false)
      if (error) return
      const list = data?._embedded?.[_.keys(data._embedded)[0]]
      setPlacticeList(list || [])
    }

    forward()
  }, [])

  return (
    <Container>
      <Segment basic loading={loading}>
        <Card.Group centered stackable>
          <RandomCard exam_id={exam_id} />
          {_(placticeList)
            .orderBy(['id'], ['asc'])
            .map(plactice => <PlacticeCard key={plactice.id} data={plactice} />)
            .value()}
        </Card.Group>
      </Segment>
    </Container>
  )
}

export default PlacticeList

const PlacticeCard: React.FC<{ data: Plactice }> = props => {
  const { data: plactice } = props

  const history = useHistory()
  const { axios } = useAxios()
  const [loading, setLoading] = useState(false)

  const handleStart = async () => {
    setLoading(true)
    const { data, error } = await axios.post<Attend>('/crud/attend', {
      plactice: `/crud/plactice/${plactice.id}`,
    })
    setLoading(false)

    if (error) return

    history.push(`/conduct/${data.id}/execute`)
  }

  return (
    <>
      <Card>
        <Layout.Image wrapped ui={false} src={''} />
        <Card.Content>
          <Card.Header content={plactice.name} />
          <Button
            primary
            fluid
            icon='pencil'
            content='Start'
            onClick={handleStart}
          />
        </Card.Content>
        <Card.Content extra>
          <Icon name='question circle outline' />
          <QuestionCount plactice_id={plactice.id} />
          Questions
        </Card.Content>
      </Card>

      <Layout.PageDimmer active={loading} />
    </>
  )
}

const QuestionCount: React.FC<{ plactice_id?: number }> = props => {
  const { plactice_id } = props
  const { axios } = useAxios()
  const [loading, setLoading] = useState(false)
  const [questionCount, setQuestionCount] = useState<number>(0)

  useEffect(() => {
    const forward = async () => {
      setLoading(true)
      const { data, error } = await axios.get<HalResource<Question>>(
        `/crud/plactice/${plactice_id}/questions`
      )
      setLoading(false)

      if (error) return
      const list = data?._embedded?.[_.keys(data._embedded)[0]]
      setQuestionCount((list || []).length)
    }

    plactice_id && forward()
  }, [])

  return loading ? <Loader active /> : <span>{questionCount}</span>
}

const RandomCard: React.FC<{ exam_id: string }> = props => {
  const { exam_id } = props

  const history = useHistory()

  const handleStart = async () => {
    history.push(`/conduct/${exam_id}/random`)
  }

  return (
    <>
      <Card>
        <Layout.Image wrapped ui={false} src={''} />
        <Card.Content>
          <Card.Header content='ラッシュ１問１答' />
          <Button
            primary
            fluid
            icon='pencil'
            content='Start'
            onClick={handleStart}
          />
        </Card.Content>
      </Card>
    </>
  )
}
