import React from 'react'
import { Route, useParams } from 'react-router-dom'
import { MySwitch } from 'base/router'
import { ToolbarProvider } from 'contexts'
import List from './List'
import InputForm from './InputForm'

export const BASE_PATH = '/crud/plactice/:plactice_id/questions'

export const Questions: React.FC = () => {
  return (
    <MySwitch>
      <Route exact path={`${BASE_PATH}`} component={Top} />
      <Route exact path={`${BASE_PATH}/new`} component={New} />
      <Route exact path={`${BASE_PATH}/:question_id/view`} component={View} />
      <Route exact path={`${BASE_PATH}/:question_id/edit`} component={Edit} />
      <Route
        exact
        path={`${BASE_PATH}/:question_id/delete`}
        component={Delete}
      />

      {/* from exam-plactice */}
      <Route
        exact
        path='/crud/exam/:exam_id/plactices/:plactice_id/questions/:question_id/edit'
        component={Edit}
      />
    </MySwitch>
  )
}

export default Questions

type PathParams = {
  plactice_id: string
  question_id: string
}

export const Top: React.FC = () => {
  const params = useParams<PathParams>()
  const { plactice_id } = params

  return (
    <ToolbarProvider>
      <List plactice_id={plactice_id} />
    </ToolbarProvider>
  )
}

export const New: React.FC = () => {
  const params = useParams<PathParams>()
  const { plactice_id } = params || {}

  return (
    <ToolbarProvider>
      <InputForm mode='new' plactice_id={plactice_id} title='設問' />
    </ToolbarProvider>
  )
}

export const View: React.FC = () => {
  const params = useParams<PathParams>()
  const { plactice_id, question_id } = params || {}

  return (
    <ToolbarProvider>
      <InputForm
        mode='view'
        plactice_id={plactice_id}
        question_id={question_id}
        title='設問'
      />
    </ToolbarProvider>
  )
}

export const Edit: React.FC = () => {
  const params = useParams<PathParams>()
  const { plactice_id, question_id } = params || {}

  return (
    <ToolbarProvider>
      <InputForm
        mode='edit'
        plactice_id={plactice_id}
        question_id={question_id}
        title='設問'
      />
    </ToolbarProvider>
  )
}

export const Delete: React.FC = () => {
  const params = useParams<PathParams>()
  const { plactice_id, question_id } = params || {}

  return (
    <ToolbarProvider>
      <InputForm
        mode='delete'
        plactice_id={plactice_id}
        question_id={question_id}
        title='設問'
      />
    </ToolbarProvider>
  )
}
