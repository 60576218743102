import React from 'react'
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import _ from 'lodash'
import moment from 'moment'
import { Grid, Button } from 'semantic-ui-react'
import { differenceInDays } from 'date-fns'
import ja from 'date-fns/locale/ja'
import JapaneseHolidays from 'japanese-holidays'
import './customDatePicker.css'

registerLocale('ja', ja)

const years = _.range(1950, moment(new Date()).year() + 5, 1)
const months = Array.from(Array(12).keys())

const holidays = _(years)
  .map(year =>
    _(JapaneseHolidays.getHolidaysOf(year))
      .map(holiday =>
        moment({
          year: year,
          month: holiday.month - 1,
          day: holiday.date,
        }).toDate()
      )
      .value()
  )
  .flatten()
  .value()

const startDate = moment({ year: _.min(years) }).startOf('year')
const endDate = moment({ year: _.max(years) }).endOf('year')
const diffDay = differenceInDays(endDate.toDate(), startDate.toDate())

const days = _([...Array(diffDay).keys()])
  .map(i => startDate.clone().add(i + 1, 'd'))
  .value()

const saturDays = _(days)
  .filter(day => day.day() === 6)
  .map(day => day.toDate())
  .value()

const sunDays = _(days)
  .filter(day => day.day() === 0)
  .map(day => day.toDate())
  .value()

export const CustomDatePicker: React.FC<ReactDatePickerProps> = props => {
  return (
    <ReactDatePicker
      locale='ja'
      highlightDates={[
        { holiday: holidays },
        { saturday: saturDays },
        { sunday: sunDays },
      ]}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <Grid columns='equal' style={{ width: '100%' }}>
          <Grid.Column>
            <Button
              as='span'
              size='tiny'
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              icon='angle left'
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <select
              value={moment(date).year()}
              onChange={({ target: { value } }) =>
                changeYear(_.toInteger(value))
              }
            >
              {years.map(option => (
                <option key={option} value={option}>
                  {option}年
                </option>
              ))}
            </select>
          </Grid.Column>
          <Grid.Column width={5}>
            <select
              value={moment(date).month()}
              onChange={({ target: { value } }) =>
                changeMonth(_.toInteger(value))
              }
            >
              {months.map(option => (
                <option key={option} value={option}>
                  {option + 1}月
                </option>
              ))}
            </select>
          </Grid.Column>
          <Grid.Column>
            <Button
              as='span'
              size='tiny'
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              icon='angle right'
            />
          </Grid.Column>
        </Grid>
      )}
      {...props}
    />
  )
}

export default CustomDatePicker
