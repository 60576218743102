import React from 'react'
import { Switch, Route } from 'react-router-dom'

import NotFound from './NotFound'

export const Error: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/error/404' component={NotFound} />
    </Switch>
  )
}

export default Error
