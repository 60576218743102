import env from './config.json'

const hostname: string = window.location.hostname
const subdomain: string = hostname.split('.').slice(0, 1)[0]

type ConfigGlobal = {
  avatarEndpoint: string
}

export const getConfigGlobal = (): ConfigGlobal => env.global

// export const getEnv = (): Env => 'development'
// export const getConfig = (): Config => env['development']['localhost']

type ConfigGroup = {
  [key: string]: Config
}

type Config = {
  realm: string
  clientId: string
  name: string
  api_endpoint: string
  auth_endpoint: string
  top_url: string
}

type Env = 'development' | 'stage' | 'production'

export const getEnv = (): Env => {
  return hostname.indexOf('localhost') >= 0
    ? 'development'
    : hostname.indexOf('stage-shikendiy.sbs.devtrial.net') >= 0
    ? 'stage'
    : 'production'
}

export const getConfig = (): Config => {
  const group: ConfigGroup = env[getEnv()] || {}
  return group[subdomain]
}
