import React, { useEffect, useState } from 'react'
import Eases from 'eases'
import useInterval from 'component/hook/useInterval'

type Props = {
  value: number
  speed?: number
  decimals?: number
}

export const NumberEasing: React.FC<Props> = props => {
  const { value = 0, speed = 500, decimals = 0 } = props

  const [renderValue, setRenderValue] = useState(value)
  const [lastTarget, setLastTarget] = useState(value)
  const [lastUpdateTime, setLastUpdateTime] = useState(new Date().getTime())

  useEffect(() => {
    setLastUpdateTime(new Date().getTime() - 16)
    setLastTarget(renderValue)
  }, [value])

  useInterval(() => {
    const currentTime = new Date().getTime()
    const absoluteProgress = (currentTime - lastUpdateTime) / speed

    if (absoluteProgress >= 1) {
      setRenderValue(value)
    } else {
      const easedProgress = Eases.quintInOut(absoluteProgress)
      setRenderValue((lastTarget + (value - lastTarget)) * easedProgress)
    }
  }, 16)

  return <>{renderValue.toFixed(decimals)}</>
}

export default NumberEasing
