import React from 'react'
import { useUser } from 'base/authenticate'

const Private: React.FC = () => {
  const { user } = useUser()
  const { authenticated, username, email } = user || {}

  return (
    <>
      <p>This is Private Page</p>
      <pre>
        {authenticated}/{username}/{email}
      </pre>
    </>
  )
}

export default Private
