import React, { useMemo } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Icon, Menu, Dropdown } from 'semantic-ui-react'
import { useUser } from 'base/authenticate'
import { MenuItems, roleFilter } from 'component/layout/MegaMenu'
import configMenu from 'config/menu.json'

export const menuSource: MenuItems = configMenu

const LeftMenu: React.FC = () => {
  const { user } = useUser()
  const { roles } = user || {}

  const filteredMenuItems = useMemo(() => roleFilter(menuSource, roles), [
    roles,
  ])

  return (
    <Menu className='left-menus' pointing secondary vertical fluid size='large'>
      {_(filteredMenuItems)
        .map(item =>
          item.subMenus && !item.url ? (
            <Dropdown
              key={item.title}
              item
              text={item.title}
              icon={<Icon name={item.icon} size='large' />}
            >
              <Dropdown.Menu>
                <Dropdown.Header icon={item.icon} content={item.title} />
                <Dropdown.Header content={item.description} />
                <Dropdown.Divider />
                {_(item.subMenus)
                  .map(submenu => (
                    <Dropdown.Item
                      key={submenu.title}
                      as={Link}
                      to={submenu.url}
                      icon={submenu.icon}
                      content={submenu.title}
                      description={submenu.description}
                    />
                  ))
                  .value()}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Menu.Item key={item.title} as={Link} to={item.url}>
              <Icon name={item.icon} size='large' />
              <span>{item.title}</span>
            </Menu.Item>
          )
        )
        .value()}
    </Menu>
  )
}

export default LeftMenu
