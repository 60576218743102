import React, { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import {
  Container,
  Segment,
  Loader,
  Message,
  Header,
  Icon,
  Card,
  Label,
} from 'semantic-ui-react'
import { useAxios, useToolbarDispatch } from 'contexts'
import {
  AttendProjection,
  PlacticeProjection,
  QuestionProjection,
  AnswerProjection,
} from 'types'
import Piece from 'component/piece'

type Props = {
  attend_id: string
}

export const Result: React.FC<Props> = props => {
  const { attend_id } = props

  return (
    <Container>
      <Segment basic>
        <DataLayer attend_id={attend_id} />
      </Segment>
    </Container>
  )
}

export default Result

const DataLayer: React.FC<Props> = props => {
  const { attend_id } = props

  const { axios } = useAxios()
  const [loading, setLoading] = useState(false)
  const [attend, setAttend] = useState<AttendProjection>()

  const { plactice, answers } = attend || {}
  const { questions } = plactice || {}

  useEffect(() => {
    const forward = async () => {
      setLoading(true)
      const { data, error } = await axios.get<AttendProjection>(
        `/crud/attend/${attend_id}?projection=attendProjection`
      )
      setLoading(false)
      if (error) return

      setAttend(data)
    }

    forward()
  }, [])

  return loading || !attend || !plactice || !questions || !answers ? (
    <Loader active />
  ) : (
    <Main
      attend={attend}
      plactice={plactice}
      questions={questions}
      answers={answers}
    />
  )
}

type MainProps = {
  attend: AttendProjection
  plactice: PlacticeProjection
  questions: QuestionProjection[]
  answers: AnswerProjection[]
}

const Main: React.FC<MainProps> = props => {
  const { plactice, questions, answers } = props

  const toolbarDispatch = useToolbarDispatch()

  useEffect(() => {
    toolbarDispatch({
      type: 'SET',
      title: `${plactice.name} 試験結果`,
    })
  }, [])

  // useMemoで正解したquestion id listを作成し、何問中何問、正解率いくつとかを計算
  const question_result_list = useMemo((): {
    question_id: number
    correct: boolean
  }[] => {
    const list = _(questions)
      .map(question => {
        const correctSelections = _(question.selections)
          .filter(selection => (selection.correct ? true : false))
          .map(selection => selection.id)
          .value()

        const answeredSlections = _(answers)
          .filter(answer => answer.question.id === question.id)
          .map(answer => answer.selection.id)
          .value()

        const isCorrect = _.isEqual(
          correctSelections.sort(),
          answeredSlections.sort()
        )

        return {
          question_id: question.id,
          correct: isCorrect,
        }
      })
      .value()

    return list
  }, [questions, answers])

  const correct_question_count = useMemo(
    () =>
      _(question_result_list)
        .filter(result => (result.correct ? true : false))
        .value().length,
    [question_result_list]
  )

  return (
    <>
      <div style={{ textAlign: 'center', marginBottom: '2em' }}>
        <Header as='h2' icon>
          <Icon name='lightbulb outline' />
          {`正解数： ${correct_question_count} / ${questions.length} (${
            (correct_question_count / questions.length) * 100
          }%)`}
        </Header>
      </div>
      <Card.Group itemsPerRow={1} centered stackable>
        {_(questions)
          .orderBy(['sequence_number'], ['asc'])
          .map((question, i) => {
            const isCorrect = _(question_result_list)
              .filter('correct')
              .map('question_id')
              .includes(question.id)

            return (
              <Card
                key={question.id}
                style={{
                  backgroundColor: isCorrect ? '#f8ffff' : '#fffaf3',
                }}
              >
                <Card.Content>
                  <Card.Header>
                    <span>{`設問${++i}`}</span>
                    <Label
                      as='span'
                      color={isCorrect ? 'blue' : 'orange'}
                      icon={isCorrect ? 'check circle outline' : 'times circle'}
                      content={isCorrect ? '正解' : '不正解'}
                      style={{ marginLeft: '2em' }}
                    ></Label>
                  </Card.Header>
                </Card.Content>

                <Card.Content>
                  <pre style={{ whiteSpace: 'pre-wrap' }}>{question.text}</pre>
                </Card.Content>

                <Card.Content>
                  {question.id && (
                    <Piece.Selections
                      question_id={question.id}
                      selectedList={
                        _(answers)
                          .map(answer => answer.selection.id)
                          .value() || []
                      }
                      resultMode
                    />
                  )}
                </Card.Content>

                <Card.Content>
                  <Message
                    header='解説'
                    content={
                      <pre style={{ whiteSpace: 'pre-wrap' }}>
                        {_.isEmpty(question.comment) ? '解説なし' : question.comment}
                      </pre>
                    }
                  />
                </Card.Content>
              </Card>
            )
          })
          .value()}
      </Card.Group>
    </>
  )
}
