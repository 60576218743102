import './style.css'
import { default as Standard } from './Standard'
import { default as Ymd } from './Ymd'
import { default as YmdPicker } from './YmdPicker'
import { default as Year } from './Year'
import { default as Ym } from './Ym'
import { default as Hm } from './Hm'
import { default as Ymdhm } from './Ymdhm'
import { default as Ymdhms } from './Ymdhms'

import { default as Int } from './Int'
import { default as IntZeroPadding } from './IntZeroPadding'
import { default as SignedInt } from './SignedInt'
import { default as SignedIntZeroPadding } from './SignedIntZeroPadding'

import { default as Numeric } from './Numeric'
import { default as NumericZeroPadding } from './NumericZeroPadding'
import { default as SignedNumeric } from './SignedNumeric'
import { default as SignedNumericZeroPadding } from './SignedNumericZeroPadding'

import { default as HalfKana } from './HalfKana'
import { default as Money } from './Money'

import { default as Email } from './Email'
import { default as Postal } from './Postal'
import { default as Phone } from './Phone'
import { default as Url } from './Url'

export default {
  Standard,
  Ymd,
  YmdPicker,
  Year,
  Ym,
  Hm,
  Ymdhm,
  Ymdhms,
  Int,
  IntZeroPadding,
  SignedInt,
  SignedIntZeroPadding,
  Numeric,
  NumericZeroPadding,
  SignedNumeric,
  SignedNumericZeroPadding,
  HalfKana,
  Money,
  Email,
  Postal,
  Phone,
  Url,
}
