import React from 'react'

import { NumberBase } from './NumberBase'

export type SignedNumericProps = React.ComponentProps<typeof NumberBase>

export const SignedNumeric: React.FC<SignedNumericProps> = props => (
  <NumberBase {...props} decimal signed />
)

export default SignedNumeric
