import React, { useMemo } from 'react'
import { List } from 'semantic-ui-react'
import _ from 'lodash'
import { useUser } from 'base/authenticate'

import { MenuBlockProps } from './MenuBlock'
import MenuItemPanel from './MenuItemPanel'
import { roleFilter } from '.'

export type MenuItemListProps = {
  subMenu: boolean
} & MenuBlockProps

export const MenuItemList: React.FC<MenuItemListProps> = props => {
  const {
    handleMenuClose,
    handleMenuSelected,
    handleClickFavorites,
    isFavorited,
    menuItems,
    subMenu,
  } = props

  const { user } = useUser()
  const { roles } = user || {}

  const filteredMenuItems = useMemo(() => roleFilter(menuItems, roles), [
    menuItems,
    roles,
  ])

  const ListItems = useMemo(
    () => (
      <>
        {_(filteredMenuItems)
          .map((item, i) => (
            <MenuItemPanel
              key={i}
              item={item}
              subMenu={subMenu}
              handleMenuClose={handleMenuClose}
              handleMenuSelected={handleMenuSelected}
              handleClickFavorites={handleClickFavorites}
              isFavorited={isFavorited}
            />
          ))
          .value()}
      </>
    ),
    [
      filteredMenuItems,
      subMenu,
      handleMenuClose,
      handleMenuSelected,
      handleClickFavorites,
      isFavorited,
    ]
  )

  return (
    <>
      {!subMenu ? (
        <List inverted divided relaxed selection>
          {ListItems}
        </List>
      ) : (
        <List.List>{ListItems}</List.List>
      )}
    </>
  )
}

export default MenuItemList
