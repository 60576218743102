import React, { useState, ReactElement, PropsWithChildren } from 'react'
import _ from 'lodash'
import { Dropdown, DropdownItemProps, Icon } from 'semantic-ui-react'

type DownloadCsvButtonProps<T> = {
  data: T[]
  originalData: T[]
  downloadFileName: string
}

export const DownloadCsvButton = <T,>(
  props: PropsWithChildren<DownloadCsvButtonProps<T>>
): ReactElement<any, any> | null => {
  const { data, originalData, downloadFileName } = props

  const [loading, setLoading] = useState<boolean>(false)

  const onClick = (_e: any, item: DropdownItemProps) => {
    setLoading(true)

    _.delay(
      () => {
        const csv =
          item.value === 'all' ? jsonToCsv(originalData) : jsonToCsv(data)

        if (!csv) {
          setLoading(false)
          return
        }

        const bom = new Uint8Array([0xef, 0xbb, 0xbf])
        const blob = new Blob([bom, csv], {
          type: 'text/csv',
        })
        const url = (window.URL || window.webkitURL).createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = downloadFileName
        a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':')
        a.click()

        setLoading(false)
      },
      100,
      'later'
    )
  }

  return (
    <>
      <Dropdown
        text='CSV'
        icon={<Icon name='cloud download' size='large' />}
        labeled
        button
        upward
        className='icon'
        loading={loading}
        disabled={loading}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            icon='file outline'
            value='limited'
            text='表示されているデータだけを出力'
            onClick={onClick}
          />
          <Dropdown.Item
            icon='file'
            value='all'
            text='全データを出力'
            onClick={onClick}
          />
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default DownloadCsvButton

const jsonToCsv = (data: any = null, delimiter = ',', linefeed = '\r\n') => {
  if (data === null || !data.length) return null

  const keys = Object.keys(data[0])
  const header = keys.join(delimiter) + linefeed

  const body = _.map(data, row =>
    _.map(keys, key => {
      const value = row[key]
      return _.isString(value)
        ? `"${value}"`
        : _.isObject(value) // when array or object
        ? `"${JSON.stringify(value).replace(/"/g, '')}"`
        : value
    })
  )

  return header + _.map(body, row => row.join(delimiter)).join(linefeed)
}
