import React from 'react'
import { Grid, Header } from 'semantic-ui-react'

export const NotFound: React.FC = () => {
  return (
    <>
      <Grid
        textAlign='center'
        style={{ height: '100vh' }}
        verticalAlign='middle'
      >
        <Grid.Column>
          <Header
            as='h1'
            textAlign='center'
            image='/images/logo.png'
            content='Not Found'
            subheader='ページが見つかりません'
            style={{ fontSize: '5em' }}
          />
        </Grid.Column>
      </Grid>
    </>
  )
}

export default NotFound
