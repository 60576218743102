import React, {
  PropsWithChildren,
  ReactElement,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import _ from 'lodash'
import { useDrag, useDrop } from 'react-dnd'
import { Resizable } from 're-resizable'
import { TableRowProps, TableCellProps, Ref } from 'semantic-ui-react'
import { useLocalStorage } from 'base/hook'
import StickyTable, { StickyTableProps } from 'base/piece/StickyTable'
import BasicDataTable from './BasicDataTable'
import './style.css'
export {
  default as ConditionDataTable,
  ConditionPanel,
} from './ConditionDataTable'

export declare namespace DataTableType {
  type DataTableProps<T, C> = {
    sortable?: boolean
  } & BasicDataTableProps<T, C>

  type BasicDataTableProps<T, C> = {
    dtid: string
    dataList?: T[]
    HeaderBlock: React.FC<any>
    RowBlock: React.FC<any>
    ConditionBlock?: React.FC<ConditionBlockProps<C>>
    downloadFilename?: string
    onSelectedChange: (selectedRows: any) => any

    // adjustWidth will fulfilled 100% table width
    // when true, set 100% table width(not x scroll)
    // and auto adjust column width for fit 100% width
    adjustWidth?: boolean
    defaultSearchOpen?: boolean
  } & StickyTableProps

  type ConditionBlockProps<C> = {
    condition?: C
    setCondition?: React.Dispatch<React.SetStateAction<C | undefined>>
    defaultCondition?: C
  }

  type HeaderBlockProps = {
    renderRow: (element: React.ReactElement) => React.ReactElement
    exchangeOrder: (sourceIndex: number, targetIndex: number) => void
    resizeCell: (celIndex: number, width: number) => void
    index: number
  }

  type HeaderRowProps = TableRowProps

  type HeaderCellProps = {
    index: number
    name: string
    sortOptions?: DataTableType.SortOption[]
    setSortOptions?: React.Dispatch<
      React.SetStateAction<DataTableType.SortOption[]>
    >
    exchangeOrder: (sourceIndex: number, targetIndex: number) => void
    resizeCell: (celIndex: number, width: number) => void
    defaultWidth?: number
  }

  type BodyBlockProps = {
    renderRow: (element: React.ReactElement) => React.ReactElement
    selected: boolean
    handleClick: () => void
  }

  type BodyRowProps = TableRowProps

  type BodyCellProps = {
    defaultWidth?: number
  } & TableCellProps

  type SortOption = {
    name: string
    direction: 'asc' | 'desc'
  }

  type DragItem = {
    type: string
    index: number
  }

  type SortableProps<T, C> = BasicDataTableProps<T, C>
}

const DND_GROUP = 'list'

export const DataTable = <T, C>(
  props: PropsWithChildren<DataTableType.DataTableProps<T, C>>
): ReactElement<any, any> | null => {
  const { sortable, ...rest } = props

  if (sortable) {
    return <Sortable {...rest} sortable={sortable} />
  }

  return <BasicDataTable {...rest} />
}

export default DataTable

export const HeaderRow: React.FC<DataTableType.HeaderRowProps> = props => (
  <StickyTable.Row {...props} />
)

export const HeaderCell: React.FC<DataTableType.HeaderCellProps> = props => {
  const {
    index,
    exchangeOrder,
    name,
    sortOptions,
    setSortOptions,
    resizeCell,
    ...rest
  } = props

  const ref = useRef<HTMLTableCellElement>(null)

  const [, drop] = useDrop({
    accept: DND_GROUP,
    drop(item: DataTableType.DragItem) {
      if (!ref.current || item.index === index) {
        return
      }
      exchangeOrder(item.index, index)
    },
  })

  const [, drag] = useDrag({
    item: { type: DND_GROUP, index },
  })

  drag(drop(ref))

  const handleSort = (e: React.MouseEvent<HTMLElement>) => {
    if (!setSortOptions) return

    // when header cell clicked with shift-key,
    // will sort by multiple columns
    const multipleSort = e.shiftKey

    setSortOptions(state => {
      const current = _(state).find({ name: name })

      if (!current) {
        return multipleSort
          ? _.unionBy([{ name: name, direction: 'asc' }], state, 'name')
          : [{ name: name, direction: 'asc' }]
      }

      return multipleSort
        ? _.unionBy(
            [
              {
                name: current.name,
                direction: current.direction === 'asc' ? 'desc' : 'asc',
              },
            ],
            state,
            'name'
          )
        : [
            {
              name: current.name,
              direction: current.direction === 'asc' ? 'desc' : 'asc',
            },
          ]
    })
  }

  return sortOptions ? (
    <FResizableCell
      ref={ref}
      {...rest}
      sorted={sortOptions && sortDirection(name, sortOptions)}
      resizeCell={resizeCell}
      onClick={handleSort}
      index={index}
      name={name}
    />
  ) : (
    <FResizableCell ref={ref} {...rest} index={index} name={name} />
  )
}

const FResizableCell = React.forwardRef<HTMLTableCellElement, TableCellProps>(
  (props, ref) => {
    const { index, resizeCell, defaultWidth, ...rest } = props

    return (
      <Ref innerRef={ref}>
        <Resizable
          as={FHeaderCell}
          enable={{
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          minWidth={5}
          defaultSize={{ width: defaultWidth, height: 'auto' }}
          size={{ width: defaultWidth, height: 'auto' }}
          onResizeStart={(
            e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
          ) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onResizeStop={(_e, _direction, refToElement: HTMLElement) => {
            resizeCell(index, refToElement.clientWidth)
          }}
          {...rest}
        />
      </Ref>
    )
  }
)

FResizableCell.displayName = 'FResizableCell'

const FHeaderCell = React.forwardRef<HTMLTableCellElement, TableCellProps>(
  (props, ref) => {
    const { children, ...rest } = props

    return (
      <Ref innerRef={ref}>
        <StickyTable.HeaderCell {...rest}>{children}</StickyTable.HeaderCell>
      </Ref>
    )
  }
)

FHeaderCell.displayName = 'FHeaderCell'

export const BodyRow: React.FC<DataTableType.BodyRowProps> = props => (
  <StickyTable.Row {...props} />
)

export const BodyCell: React.FC<DataTableType.BodyCellProps> = props => {
  const { defaultWidth, content, ...rest } = props
  return (
    <StickyTable.Cell
      title={content ? content : undefined}
      content={content}
      {...rest}
    />
  )
}

const sortDirection = (
  name: string,
  sortOtions: DataTableType.SortOption[]
): 'ascending' | 'descending' | undefined => {
  const direction = _(sortOtions).find({ name: name })?.direction
  return direction
    ? direction === 'asc'
      ? 'ascending'
      : direction === 'desc'
      ? 'descending'
      : undefined
    : undefined
}

export const Sortable = <T, C>(
  props: PropsWithChildren<DataTableType.SortableProps<T, C>>
): ReactElement<any, any> | null => {
  const { dtid, dataList, HeaderBlock, ...rest } = props

  const [sortOptions, setSortOptions] = useLocalStorage<
    DataTableType.SortOption[]
  >({
    dbkey: 'datatable',
    key: `${dtid}-sortOptions`,
    initial: [],
  })

  const sortedDataList = useMemo(
    () =>
      _.orderBy<T[]>(
        dataList,
        _.map(sortOptions, 'name'),
        _.map(sortOptions, 'direction')
      ),
    [dataList, sortOptions]
  )

  const SortHeaderBlock = useCallback(
    props => (
      <HeaderBlock
        {...props}
        sortOptions={sortOptions}
        setSortOptions={setSortOptions}
      />
    ),
    [sortOptions, setSortOptions]
  )

  return (
    <BasicDataTable
      {...rest}
      dtid={dtid}
      dataList={sortedDataList}
      HeaderBlock={SortHeaderBlock}
      sortable
    />
  )
}
