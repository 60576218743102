import React from 'react'
import { Image as SUIImage } from 'semantic-ui-react'
import noImage from './noimage.jpg'

type Props = React.ComponentProps<typeof SUIImage>

export const Image: React.FC<Props> = props => {
  return (
    <SUIImage
      {...props}
      src={props.src ? props.src : noImage}
      onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = noImage
      }}
    />
  )
}

export default Image
