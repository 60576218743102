import React from 'react'

import { NumberBase } from './NumberBase'

export type NumericZeroPaddingProps = React.ComponentProps<typeof NumberBase>

export const NumericZeroPadding: React.FC<NumericZeroPaddingProps> = props => (
  <NumberBase {...props} decimal zeroPadding />
)

export default NumericZeroPadding
