import React from 'react'
import { Input } from 'semantic-ui-react'
import { Controller } from 'react-hook-form'
import { Standard } from './Standard'
import ErrorMessageLabel from 'base/control/ErrorLabel'
import * as DateParser from 'base/util/DateParser'
import CustomDatePicker from 'base/piece/CustomDatePicker'

type YmdPickerProps = {
  defaultValue?: string | null
} & Omit<React.ComponentProps<typeof Standard>, 'onChange'>

export const YmdPicker: React.FC<YmdPickerProps> = props => {
  const {
    control,
    name,
    required,
    defaultValue,
    validate,
    min,
    max,
    minLength,
    maxLength,
    pattern,
    errors,
  } = props

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{
          required: required ? 'This is required' : undefined,
          min: min
            ? { value: min, message: `expect min ${min} items` }
            : undefined,
          max: max
            ? { value: max, message: `expect max ${max} items` }
            : undefined,
          minLength: minLength
            ? {
                value: minLength,
                message: `expect ${minLength} length or greater`,
              }
            : undefined,
          maxLength: maxLength
            ? {
                value: maxLength,
                message: `expect ${maxLength} length or less`,
              }
            : undefined,
          pattern,
          validate: validate ? validate : () => true,
        }}
        defaultValue={defaultValue}
        valueName='selected'
        render={({ onChange, onBlur, value, name }) => (
          <CustomDatePicker
            onBlur={onBlur}
            onChange={(selected: Date) =>
              onChange(DateParser.dateToYmd(selected))
            }
            selected={DateParser.ymdstrToDate(value)}
            value={value}
            name={name}
            dateFormat='yyyy/MM/dd'
            isClearable
            autoComplete='off'
            customInput={<Input />}
          />
        )}
      />
      <ErrorMessageLabel errors={errors} name={name} />
    </>
  )
}

YmdPicker.defaultProps = {
  required: false,
  defaultValue: null,
}

export default YmdPicker
