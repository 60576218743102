import React, { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import {
  Container,
  Loader,
  Button,
  Card,
  Label,
  Message,
  Statistic,
  Icon,
} from 'semantic-ui-react'
import { useAxios, useToolbarDispatch } from 'contexts'
import { QuestionProjection, ExamProjection } from 'types'
import Piece from 'component/piece'
import { useInterval } from 'component/hook/useInterval'
import './style.css'

type Props = {
  exam_id: string
}

export const Random: React.FC<Props> = props => {
  return (
    <Container>
      <DataLayer {...props} />
    </Container>
  )
}

export default Random

const DataLayer: React.FC<Props> = props => {
  const { exam_id } = props

  const { axios } = useAxios()
  const [loading, setLoading] = useState(false)
  const [exam, setExam] = useState<ExamProjection>()

  useEffect(() => {
    const forward = async () => {
      setLoading(true)
      const { data, error } = await axios.get<ExamProjection>(
        `/crud/exam/${exam_id}?projection=examProjection`
      )
      setLoading(false)
      if (error) return

      setExam(data)
    }

    forward()
  }, [])

  const questions = useMemo(
    () =>
      _(exam?.plactices)
        .map(plactice =>
          _(plactice.questions)
            .map(question => ({ ...question, plactice_id: plactice.id }))
            .value()
        )
        .flatten()
        .shuffle()
        .value(),
    [exam]
  )

  console.log('questions', questions)

  return loading || !exam || !questions ? (
    <Loader active />
  ) : (
    <Main exam={exam} questions={questions} />
  )
}

type MainProps = {
  exam: ExamProjection
  questions: (QuestionProjection & { plactice_id?: number })[]
}

const Main: React.FC<MainProps> = props => {
  const { exam, questions } = props

  const toolbarDispatch = useToolbarDispatch()

  const history = useHistory()
  const [selectedList, setSelectedList] = useState<number[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [resultList, setResultList] = useState<
    { index: number; result: boolean }[]
  >([])
  const [mode, setMode] = useState<'question' | 'answer'>('question')

  const [startTime] = useState(new Date().getTime())
  const [processedTime, setProcessedTime] = useState(new Date().getTime())

  useEffect(() => {
    toolbarDispatch({
      type: 'SET',
      title: `ラッシュ１問１答 ${exam.name}`,
    })
  }, [])

  useInterval(() => {
    setProcessedTime(new Date().getTime())
  }, 1000)

  const question = useMemo(
    () => questions?.[currentIndex],
    [questions, currentIndex]
  )

  const answeredCount = useMemo(() => resultList.length, [resultList])
  const correctedCount = useMemo(
    () =>
      _(resultList)
        .filter(row => row.result === true)
        .value().length,
    [resultList]
  )

  const stopWatch = useMemo(() => {
    const h = (processedTime - startTime) / (1000 * 60 * 60)
    const m = (h - Math.floor(h)) * 60
    const s = (m - Math.floor(m)) * 60

    return `${('00' + Math.floor(h)).slice(-2)}:
    ${('00' + Math.floor(m)).slice(-2)}:
    ${('00' + Math.round(s)).slice(-2)}`
  }, [startTime, processedTime])

  return (
    <div style={{ marginTop: 50 }}>
      <div style={{ display: 'flow-root' }}>
        <Label icon='clock' content={stopWatch} style={{ float: 'right' }} />
      </div>

      <Statistic.Group widths={3} size='small' style={{ marginBottom: 20 }}>
        <Statistic>
          <Statistic.Label>回答済</Statistic.Label>

          <Statistic.Value>{answeredCount}</Statistic.Value>
        </Statistic>
        <Statistic>
          <Statistic.Label>正解</Statistic.Label>

          <Statistic.Value>
            <Icon name='check' color='green' />
            {correctedCount}
          </Statistic.Value>
        </Statistic>
        <Statistic>
          <Statistic.Label>正解率</Statistic.Label>
          <Statistic.Value>
            <Piece.NumberEasing
              value={
                correctedCount >= 1 && answeredCount >= 1
                  ? (correctedCount / answeredCount) * 100
                  : 0
              }
              decimals={0}
            />
            %
          </Statistic.Value>
        </Statistic>
      </Statistic.Group>

      <Card.Group itemsPerRow={1} centered stackable>
        <Card className='questionCard'>
          <Card.Content>
            <Card.Header>
              <span>{`設問${currentIndex + 1}`}</span>
              <Button
                icon='pencil'
                style={{ float: 'right' }}
                onClick={() =>
                  window.open(
                    `/crud/exam/${exam.id}/plactices/${question.plactice_id}/questions/${question.id}/edit`
                  )
                }
              />
            </Card.Header>
          </Card.Content>

          <Card.Content>
            <pre style={{ whiteSpace: 'pre-wrap' }}>{question.text}</pre>
            <p>
              {question.selectable_answer_count &&
                `${question.selectable_answer_count}つ選択`}
            </p>
          </Card.Content>

          {mode === 'question' ? (
            <Card.Content>
              {question.id && (
                <Piece.Selections
                  question_id={question.id}
                  selectedList={selectedList}
                  setSelectedList={setSelectedList}
                />
              )}
            </Card.Content>
          ) : mode === 'answer' ? (
            <ResultBlock question={question} selectedList={selectedList} />
          ) : (
            <></>
          )}

          {mode === 'question' && (
            <Card.Content>
              <Button
                content='回答する'
                primary
                fluid
                size='huge'
                style={{ padding: '.5em 1em .5em' }}
                onClick={() => {
                  const correctSelections = _(question.selections)
                    .filter(selection => (selection.correct ? true : false))
                    .map(selection => selection.id)
                    .value()

                  const isCorrect = _.isEqual(
                    correctSelections.sort(),
                    selectedList.sort()
                  )

                  setResultList(state =>
                    _([{ index: currentIndex, result: isCorrect }])
                      .unionBy(state, 'index')
                      .value()
                  )

                  setMode('answer')
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
              />
            </Card.Content>
          )}

          <Card.Content>
            <Button.Group fluid size='huge'>
              <Button
                icon='left chevron'
                style={{ padding: '.5em 1em .5em' }}
                onClick={() => {
                  setCurrentIndex(state => --state)
                  setSelectedList([])
                  setMode('question')
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
                disabled={currentIndex <= 0}
              />

              {currentIndex < questions.length - 1 && (
                <Button
                  primary={mode === 'answer' ? true : false}
                  icon='right chevron'
                  style={{ padding: '.5em 1em .5em' }}
                  onClick={() => {
                    setCurrentIndex(state => ++state)
                    setSelectedList([])
                    setMode('question')
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }}
                />
              )}

              {currentIndex >= questions.length - 1 && (
                <Button
                  size='huge'
                  style={{ padding: '.5em' }}
                  icon='check'
                  content='Finish'
                  primary
                  onClick={() => history.push('/')}
                />
              )}
            </Button.Group>
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
  )
}

type ResultBlockProps = {
  question: QuestionProjection
  selectedList: number[]
}

const ResultBlock: React.FC<ResultBlockProps> = props => {
  const { question, selectedList } = props

  const isCorrect = useMemo(() => {
    const correctSelections = _(question.selections)
      .filter(selection => (selection.correct ? true : false))
      .map(selection => selection.id)
      .value()

    return _.isEqual(correctSelections.sort(), selectedList.sort())
  }, [question, selectedList])

  return (
    <>
      <Card.Content>
        <Label
          as='span'
          size='huge'
          color={isCorrect ? 'blue' : 'orange'}
          icon={isCorrect ? 'check circle outline' : 'times circle'}
          content={isCorrect ? '正解' : '不正解'}
          style={{ width: '100%' }}
        />
      </Card.Content>

      <Piece.Selections
        question_id={question.id}
        selectedList={selectedList}
        resultMode
      />

      <Card.Content>
        <Message
          header='解説'
          content={
            <pre style={{ whiteSpace: 'pre-wrap' }}>
              {_.isEmpty(question.comment) ? '解説なし' : question.comment}
            </pre>
          }
        />
      </Card.Content>
    </>
  )
}
