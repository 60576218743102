import React, { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { Container, Loader, Button, Progress, Card } from 'semantic-ui-react'
import { useAxios, useToolbarDispatch } from 'contexts'
import {
  PlacticeProjection,
  QuestionProjection,
  Answer,
  FinishedAttend,
  AttendProjection,
} from 'types'
import Piece from 'component/piece'

type Props = {
  attend_id: string
}

export const Input: React.FC<Props> = props => {
  const { attend_id } = props

  return (
    <Container>
      <DataLayer attend_id={attend_id} />
    </Container>
  )
}

export default Input

const DataLayer: React.FC<Props> = props => {
  const { attend_id } = props

  const { axios } = useAxios()
  const [loading, setLoading] = useState(false)
  const [attend, setAttend] = useState<AttendProjection>()

  const { plactice } = attend || {}
  const { questions } = plactice || {}

  useEffect(() => {
    const forward = async () => {
      setLoading(true)
      const { data, error } = await axios.get<AttendProjection>(
        `/crud/attend/${attend_id}?projection=attendProjection`
      )
      setLoading(false)
      if (error) return

      setAttend(data)
    }

    forward()
  }, [])

  return loading || !attend || !plactice || !questions ? (
    <Loader active />
  ) : (
    <Main attend={attend} plactice={plactice} questions={questions} />
  )
}

type MainProps = {
  attend: AttendProjection
  plactice: PlacticeProjection
  questions: QuestionProjection[]
}

const Main: React.FC<MainProps> = props => {
  const { attend, plactice, questions } = props

  const { axios } = useAxios()
  const toolbarDispatch = useToolbarDispatch()

  const history = useHistory()
  const [selectedList, setSelectedList] = useState<number[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    toolbarDispatch({
      type: 'SET',
      title: plactice.name,
    })
  }, [])

  const question = useMemo(
    () =>
      _(questions).orderBy(['sequence_number'], ['asc']).value()[currentIndex],
    [questions, currentIndex]
  )

  const handleFinish = async () => {
    await save()
    history.push(`/conduct/${attend.id}/result`)
  }

  const save = async () => {
    await Promise.all(
      selectedList.map(async selection_id => {
        await axios.post<Answer>('/crud/answer', {
          attend: `/crud/attend/${attend.id}`,
          selection: `/crud/selection/${selection_id}`,
        })
      })
    )

    await axios.post<FinishedAttend>('/crud/finished_attend', {
      attend: `/crud/attend/${attend.id}`,
    })
  }

  return (
    <>
      <Progress
        progress='ratio'
        value={currentIndex + 1}
        total={questions.length}
      />
      <Card.Group itemsPerRow={1} centered stackable>
        <Card>
          <Card.Content>
            <Card.Header>
              <span>{`設問${currentIndex + 1}`}</span>
            </Card.Header>
          </Card.Content>

          <Card.Content>
            <pre style={{ whiteSpace: 'pre-wrap' }}>{question.text}</pre>
            <p>
              {question.selectable_answer_count &&
                `${question.selectable_answer_count}つ選択`}
            </p>
          </Card.Content>

          <Card.Content>
            {question.id && (
              <Piece.Selections
                question_id={question.id}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
              />
            )}
          </Card.Content>
          <Card.Content>
            {currentIndex > 0 && (
              <Button
                primary
                icon='left chevron'
                size='huge'
                style={{ padding: '.5em 1em .5em' }}
                onClick={() => setCurrentIndex(state => --state)}
              />
            )}

            {currentIndex < questions.length - 1 && (
              <Button
                primary
                icon='right chevron'
                size='huge'
                style={{ padding: '.5em 1em .5em' }}
                floated='right'
                onClick={() => setCurrentIndex(state => ++state)}
              />
            )}

            {currentIndex >= questions.length - 1 && (
              <Button
                size='huge'
                style={{ padding: '.5em' }}
                labelPosition='right'
                icon='check'
                content='Finish'
                primary
                floated='right'
                onClick={handleFinish}
              />
            )}
          </Card.Content>
        </Card>
      </Card.Group>
    </>
  )
}
