import React from 'react'
import { Header, Dimmer, Icon, SemanticICONS } from 'semantic-ui-react'

type Props = {
  active?: boolean
  content?: React.ReactNode
  icon?: SemanticICONS
}

export const PageDimmer: React.FC<Props> = props => {

  const { active, content, icon } = props

  return (
    <Dimmer page active={active ? true : false}>
      <Header as='h2' icon inverted>
        <Icon loading name={icon || 'spinner'} />
        {content || 'Loading...'}
      </Header>
    </Dimmer>
  )

}

export default PageDimmer
