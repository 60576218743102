import React from 'react'
import _ from 'lodash'

import { Standard } from './Standard'
import * as Validations from 'base/control/validations'

export type HalfKanaProps = React.ComponentProps<typeof Standard>

export const HalfKana: React.FC<HalfKanaProps> = props => {
  const { validate } = props

  const handleEdit = (value: string): string | null => {
    return value
  }

  return (
    <Standard
      {...props}
      type='text'
      editValue={handleEdit}
      validate={_.assign({}, validate, { halfKana: Validations.halfKana })}
    />
  )
}

export default HalfKana
