import React from 'react'
import _ from 'lodash'
import { Controller, Control, FieldErrors } from 'react-hook-form'
import { CheckboxProps, Form, Checkbox } from 'semantic-ui-react'
import ErrorMessageLabel from 'base/control/ErrorLabel'
import message from 'base/message'

type ItemValue = {
  name: string
  value: string | number
}

export type StandardProps = {
  control: Control
  errors: FieldErrors
  name: string
  title?: string
  defaultValue?: string[] | number[] | []
  itemValues: ItemValue[]
  inline?: boolean
  required?: boolean
  validate?: Record<string, (value: unknown) => boolean>
} & CheckboxProps

export const Standard: React.FC<StandardProps> = props => {
  const {
    control,
    errors,
    name,
    defaultValue,
    itemValues,
    inline,
    required,
    validate,
    onChange: _onChange,
    ...rest
  } = props

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{
          validate: _.assign({}, validate, {
            atLeastOne: (values: unknown[]) =>
              !required
                ? true
                : values.length
                ? true
                : message('validate.atLeastOne'),
          }),
        }}
        render={({ onChange, onBlur, value: values, name }) => (
          <Form.Group
            style={{
              display: inline ? 'flex' : 'block',
            }}
          >
            {_(itemValues)
              .map(item => (
                <Form.Field key={item.value}>
                  <Checkbox
                    onBlur={onBlur}
                    onChange={(e, data) => {
                      const { value, checked } = data
                      onChange(
                        checked
                          ? _.union([value], values)
                          : _.filter(values, v => v !== value)
                      )
                      _onChange && _onChange(e, data)
                    }}
                    checked={_.includes(values, item.value)}
                    value={item.value}
                    label={item.name}
                    name={name}
                    {...rest}
                  />
                </Form.Field>
              ))
              .value()}
          </Form.Group>
        )}
      />
      <ErrorMessageLabel errors={errors} name={name} />
    </>
  )
}

export default Standard
