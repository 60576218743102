import React from 'react'
import _ from 'lodash'

import { Standard } from './Standard'
import * as Validations from 'base/control/validations'

export type PostalProps = React.ComponentProps<typeof Standard>

export const Postal: React.FC<PostalProps> = props => {
  const { validate } = props

  const handleEdit = (value: string): string | null => {
    if (!/^[0-9]{7}$/.test(value.replace(/-/g, ''))) return value

    return value
      .toString()
      .replace(/-/g, '')
      .replace(/^(\d{3})(\d{4})$/, '$1-$2')
  }

  return (
    <Standard
      {...props}
      type='tel'
      editValue={handleEdit}
      validate={_.assign({}, validate, { postal: Validations.postal })}
    />
  )
}

export default Postal
