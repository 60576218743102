import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import _ from 'lodash'
import { Message, MessageProps, Icon } from 'semantic-ui-react'
import { Animate } from 'react-simple-animate'
import './style.css'

/**
 *
 * usage useToast:
 * -- before use useToast, please wrap your root component by ToastProvider
 *
 * import { useToast } from 'base/toast
 *
 * const { notice, noticeWarning, noticeError } = useToast()
 *
 * notice({
 *   header: 'This is notice header',
 *   content: 'This is notice content'
 * })
 *
 */

type NoticeOption = {
  autoRemoveInterval?: number | null
} & MessageProps

type CtxType = {
  notice: (props: NoticeOption) => number
  noticeWarning: (props: NoticeOption) => number
  noticeError: (props: NoticeOption) => number
  remove: (id: number) => void
}

const Ctx = createContext<CtxType>({
  notice: () => 0,
  noticeWarning: () => 0,
  noticeError: () => 0,
  remove: () => null,
})

const ToastContainer: React.FC = props => (
  <div style={{ position: 'fixed', right: 0, top: 0 }} {...props} />
)

type ToastProps = {
  onDismiss: (...arg: any) => void
  option: NoticeOption
}

const Toast: React.FC<ToastProps> = props => {
  const { option, onDismiss } = props
  const { autoRemoveInterval, ...other } = option

  const [show, setShow] = useState(true)

  const handleClick = () => {
    setShow(false)
    _.delay(() => onDismiss(), 500, 'later')
  }

  useEffect(() => {
    if (!autoRemoveInterval) return
    _.delay(
      () => {
        setShow(false)
        _.delay(() => onDismiss(), 500, 'later')
      },
      autoRemoveInterval,
      'later'
    )
  }, [autoRemoveInterval, onDismiss])

  return (
    <Animate
      play={show}
      start={{ transform: 'translateX(500px)' }}
      end={{ transform: 'translateX(0px)' }}
    >
      <Message {...other} onClick={handleClick} className='toast-message' />
    </Animate>
  )
}

let toastCount = 0

type ToastData = {
  id: number
  option: NoticeOption
}

const defaultOption: NoticeOption = {
  info: true,
  size: 'small',
  icon: <Icon name='info circle' />,
  autoRemoveInterval: 2000,
}

export const ToastProvider: React.FC = props => {
  const { children } = props

  const [toasts, setToasts] = useState<ToastData[]>([])

  const notice = useCallback(
    (option: NoticeOption) => {
      const id = toastCount++
      setToasts(toasts => [
        ...toasts,
        { id: toastCount++, option: _.assign({}, defaultOption, option) },
      ])
      return id
    },
    [setToasts]
  )

  const noticeWarning = useCallback(
    (option: NoticeOption) => {
      const id = toastCount++
      setToasts(toasts => [
        ...toasts,
        {
          id: toastCount++,
          option: _.assign({}, defaultOption, option, {
            warning: true,
            visible: true,
            icon: <Icon name='exclamation triangle' />,
          }),
        },
      ])
      return id
    },
    [setToasts]
  )

  const noticeError = useCallback(
    (option: NoticeOption) => {
      const id = toastCount++
      setToasts(toasts => [
        ...toasts,
        {
          id: toastCount++,
          option: _.assign({}, defaultOption, option, {
            error: true,
            visible: true,
            icon: <Icon name='exclamation circle' />,
            autoRemoveInterval: null,
          }),
        },
      ])
      return id
    },
    [setToasts]
  )

  const remove = (id: number) => setToasts(toasts.filter(t => t.id !== id))

  const onDismiss = (id: number) => () => remove(id)

  return (
    <Ctx.Provider value={{ notice, noticeWarning, noticeError, remove }}>
      {children}
      <ToastContainer>
        {toasts.map(({ id, option }) => (
          <Toast key={id} onDismiss={onDismiss(id)} option={option} />
        ))}
      </ToastContainer>
    </Ctx.Provider>
  )
}

export const useToast = (): CtxType => useContext<CtxType>(Ctx)
