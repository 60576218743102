import React, { createContext, useReducer, useContext } from 'react'

type StoreProviderProps<Store> = {
  initialState?: Store
}

export const contextBuilder = <Store, Action>(
  initialState: Store,
  reducer: React.Reducer<Store, Action>
): readonly [
  React.FC<StoreProviderProps<Store>>,
  { (): Store; <T>(selector: (state: Store) => T): T },
  () => React.Dispatch<Action>
] => {
  const StoreContext = createContext(initialState)
  const DispatchContext = createContext<React.Dispatch<Action>>(() => {})

  const StoreProvider: React.FC<StoreProviderProps<Store>> = props => {
    const [state, dispatch] = useReducer(
      reducer,
      props.initialState ?? initialState
    )

    return (
      <StoreContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          {props.children}
        </DispatchContext.Provider>
      </StoreContext.Provider>
    )
  }

  function useSelector(): Store
  function useSelector<T>(selector: (state: Store) => T): T
  function useSelector<T>(selector?: (state: Store) => T) {
    const state = useContext(StoreContext)
    return selector !== undefined ? selector(state) : state
  }

  const useDispatch = () => useContext(DispatchContext)

  return [StoreProvider, useSelector, useDispatch] as const
}

export default contextBuilder
