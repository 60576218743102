import React, { useMemo } from 'react'
import { Label, LabelProps } from 'semantic-ui-react'

type PaginateLabel = {
  total: number
  start: number
  end: number
} & LabelProps

export const PaginateLabel: React.FC<PaginateLabel> = props => {
  const { total, start, end, ...rest } = props

  const displayText = useMemo(
    () =>
      end > total
        ? `全${total}件中${total}件を表示`
        : `全${total}件中${start}件目〜${end}件目を表示`,
    [total, start, end]
  )

  return <Label content={displayText} {...rest} size='large' />
}

export default PaginateLabel

PaginateLabel.defaultProps = {
  total: 0,
  start: 0,
  end: 0,
}
