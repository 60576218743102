import { default as ApplicationHeader } from './ApplicationHeader'
import { default as Page } from './Page'
import { default as Image } from './Image'
import { default as PageDimmer } from './PageDimmer'

export default {
  ApplicationHeader,
  Page,
  Image,
  PageDimmer,
}
