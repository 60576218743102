import React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useUser } from 'base/authenticate'
import DashBoard from './DashBoard'
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Segment,
} from 'semantic-ui-react'
import './top.css'

const Top: React.FC = () => {
  const { user } = useUser()
  const { authenticated } = user || {}

  if (authenticated) return <DashBoard />

  return <HomepageLayout />
}

export default Top

const HomepageLayout: React.FC = () => {
  const { keycloak } = useKeycloak()

  const handleLoginClick = () =>
    (window.location.href = keycloak.createLoginUrl())

  return (
    <>
      <Segment
        inverted
        textAlign='center'
        style={{ minHeight: 350, padding: '1em 0em' }}
        vertical
      >
        <Container text>
          <Header
            as='h1'
            content='Shiken-DIY'
            inverted
            style={{
              fontSize: '3em',
              fontWeight: 'normal',
              marginBottom: 0,
              marginTop: '1.5em',
            }}
          />
          <Header
            as='h2'
            content='Tests you build yourself.'
            inverted
            style={{
              fontSize: '1.5em',
              fontWeight: 'normal',
              marginTop: '0.5em',
            }}
          />
          <Button primary size='huge' onClick={handleLoginClick}>
            Get Started
            <Icon name='arrow right' />
          </Button>
        </Container>
      </Segment>

      <Segment style={{ padding: '8em 0em' }} vertical>
        <Grid container stackable verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as='h3' style={{ fontSize: '2em' }}>
                Partner for all test takers
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                All exam questions can be created by the candidate himself. In
                addition, the practice exams you create will be open to other
                test takers so that you can always experience the latest exam.
              </p>
              <Header as='h3' style={{ fontSize: '2em' }}>
                Follow in the footsteps of others
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                Following the study time and footsteps of the mock exams that
                other candidates have taken to pass will surely be a shortcut to
                your passing.
              </p>
            </Grid.Column>
            <Grid.Column floated='right' width={6}>
              <Image bordered rounded size='large' src='/images/noimage.png' />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign='center'>
              <Button size='huge'>Check Them Out</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment style={{ padding: '0em' }} vertical>
        <Grid celled='internally' columns='equal' stackable>
          <Grid.Row textAlign='center'>
            <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
              <Header as='h3' style={{ fontSize: '2em' }}>
                What a Company
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                That is what they all say about us
              </p>
            </Grid.Column>
            <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
              <Header as='h3' style={{ fontSize: '2em' }}>
                I shouldn&apos;t have gone with their competitor.
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                <Image avatar src='/images/noimage.png' />
                <b>Nan</b> Chief Fun Officer Acme Toys
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment style={{ padding: '8em 0em' }} vertical>
        <Container text>
          <Header as='h3' style={{ fontSize: '2em' }}>
            Breaking The Grid, Grabs Your Attention
          </Header>
          <p style={{ fontSize: '1.33em' }}>
            Instead of focusing on content creation and hard work, we have
            learned how to master the art of doing nothing by providing massive
            amounts of whitespace and generic content that can seem massive,
            monolithic and worth your attention.
          </p>
          <Button as='a' size='large'>
            Read More
          </Button>

          <Divider
            as='h4'
            className='header'
            horizontal
            style={{ margin: '3em 0em', textTransform: 'uppercase' }}
          >
            <a href='#'>Case Studies</a>
          </Divider>

          <Header as='h3' style={{ fontSize: '2em' }}>
            Did We Tell You About Our Bananas?
          </Header>
          <p style={{ fontSize: '1.33em' }}>
            Yes I know you probably disregarded the earlier boasts as
            non-sequitur filler content, but it&apos;s really true. It took
            years of gene splicing and combinatory DNA research, but our bananas
            can really dance.
          </p>
          <Button as='a' size='large'>
            I&apos;m Still Quite Interested
          </Button>
        </Container>
      </Segment>

      <Segment inverted vertical style={{ padding: '5em 0em' }}>
        <Container>
          <Grid divided inverted stackable>
            <Grid.Row>
              <Grid.Column width={3}>
                <Header inverted as='h4' content='About' />
                <List link inverted>
                  <List.Item as='a'>Sitemap</List.Item>
                  <List.Item as='a'>Contact Us</List.Item>
                  <List.Item as='a'>Religious Ceremonies</List.Item>
                  <List.Item as='a'>Gazebo Plans</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header inverted as='h4' content='Services' />
                <List link inverted>
                  <List.Item as='a'>Banana Pre-Order</List.Item>
                  <List.Item as='a'>DNA FAQ</List.Item>
                  <List.Item as='a'>How To Access</List.Item>
                  <List.Item as='a'>Favorite X-Men</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={7}>
                <Header as='h4' inverted>
                  Footer Header
                </Header>
                <p>
                  Extra space for a call to action inside the footer that could
                  help re-engage users.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </>
  )
}
