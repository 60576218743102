import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { Container, Segment, Card } from 'semantic-ui-react'
import { useAxios, useToolbarDispatch } from 'contexts'
import { HalResource, Exam } from 'types'
import Layout from 'component/layout'

type Props = Record<string, unknown>

export const ExamList: React.FC<Props> = props => {
  const toolbarDispatch = useToolbarDispatch()
  const { axios } = useAxios()
  const [loading, setLoading] = useState(false)
  const [examList, setExamList] = useState<Exam[]>([])

  useEffect(() => {
    toolbarDispatch({
      type: 'SET',
      title: '試験の選択',
    })
  }, [])

  useEffect(() => {
    const forward = async () => {
      setLoading(true)
      const { data, error } = await axios.get<HalResource<Exam>>('/crud/exam')
      setLoading(false)
      if (error) return
      const list = data?._embedded?.[_.keys(data._embedded)[0]]
      setExamList(list || [])
    }

    forward()
  }, [])

  return (
    <Container>
      <Segment basic loading={loading}>
        <Card.Group centered stackable>
          {_(examList)
            .orderBy(['id'], ['asc'])
            .map(exam => <ExamCard key={exam.id} data={exam} />)
            .value()}
        </Card.Group>
      </Segment>
    </Container>
  )
}

export default ExamList

const ExamCard: React.FC<{ data: Exam }> = props => {
  const { data } = props
  const history = useHistory()

  return (
    <Card onClick={() => history.push(`/attend/exam/${data.id}/plactices`)}>
      <Layout.Image wrapped ui={false} src={data.image_url || ''} />
      <Card.Content>
        <Card.Header content={data.name} />
      </Card.Content>
    </Card>
  )
}
