import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { Grid, Form, Button } from 'semantic-ui-react'
import { useToolbarDispatch } from 'contexts'
import { useAxios } from 'contexts'
import { Plactice } from 'types'
import Textbox from 'component/control/textbox'
import ExamForm from 'component/page/Crud/Exam/InputForm'
import QuestionBulkInput from 'component/page/Crud/Exam/Plactices/Questions/BulkInput'
import Layout from 'component/layout'

type Props =
  | {
      mode: 'new'
      exam_id?: string
      plactice_id?: string
      onSave?: () => void
      title?: string
    }
  | {
      mode: 'view' | 'edit' | 'delete'
      exam_id?: string
      plactice_id: string
      onSave?: () => void
      title?: string
    }

type Inputs = Omit<Plactice, 'id'>

const InputForm: React.FC<Props> = props => {
  const { mode, exam_id, plactice_id, onSave, title } = props

  const SELF_PATH = `/crud/plactice`
  const BASE_PATH = `/crud/exam/${exam_id}/plactices`

  const locked = mode === 'view' || mode === 'delete' ? true : false

  const { axios } = useAxios()
  const history = useHistory()
  const toolbarDispatch = useToolbarDispatch()

  const { handleSubmit, errors, control, setValue } = useForm<Inputs>({
    criteriaMode: 'all',
  })

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    toolbarDispatch({
      type: 'SET',
      title: '練習問題',
    })
  }, [])

  const onSubmit = (model: Inputs) => {
    switch (mode) {
      case 'new':
        postApi(model)
        break
      case 'edit':
        patchApi(model)
        break
      case 'delete':
        deleteApi()
        break
    }
  }

  useEffect(() => {
    const getApi = async () => {
      setLoading(true)
      const { data, error } = await axios.get<Plactice>(
        `${BASE_PATH}/${plactice_id}`
      )
      setLoading(false)
      if (error) {
        console.log('get error', error)
        return
      }

      const { name } = data || {}
      setValue('name', name, { shouldValidate: true })
    }

    if (mode !== 'new') getApi()
  }, [mode, BASE_PATH, setValue])

  const postApi = async (model: Inputs) => {
    setLoading(true)
    const { data, error } = await axios.post<Plactice>(
      `${SELF_PATH}`,
      exam_id
        ? _.assign({}, model, { exams: [`/crud/exam/${exam_id}`] })
        : model
    )
    setLoading(false)

    if (error) {
      console.log('post error', error)
      return
    }
    console.log('post data:', data)
    onSave ? onSave() : history.push(BASE_PATH)
  }

  const patchApi = async (model: Inputs) => {
    setLoading(true)
    const { data, error } = await axios.patch<Plactice>(
      `${SELF_PATH}/${plactice_id}`,
      model
    )
    setLoading(false)

    if (error) {
      console.log('patch error', error)
      return
    }
    console.log('patch data:', data)
    onSave ? onSave() : history.push(BASE_PATH)
  }

  const deleteApi = async () => {
    setLoading(true)
    const { data, error } = await axios.delete<Plactice>(
      `${SELF_PATH}/${plactice_id}`
    )
    setLoading(false)

    if (error) {
      console.log('delete error', error)
      return
    }
    console.log('delete data:', data)
    onSave ? onSave() : history.push(BASE_PATH)
  }

  return (
    <Grid>
      {exam_id && (
        <Grid.Row>
          <Grid.Column>
            <ExamForm mode='view' exam_id={exam_id} />
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column>
          <Layout.Page title={title} loading={loading}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Field
                required
                error={errors.name ? true : false}
                disabled={locked}
              >
                <label>name</label>
                <Textbox.Base.Standard
                  control={control}
                  errors={errors}
                  name='name'
                  placeholder='enter name'
                  required
                />
              </Form.Field>

              {mode !== 'view' && <Button type='submit' content='Submit' />}
            </Form>

            {plactice_id && <QuestionBulkInput plactice_id={plactice_id} />}
          </Layout.Page>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default InputForm
