import React from 'react'
import { Route, useParams } from 'react-router-dom'
import { MySwitch } from 'base/router'
import { ToolbarProvider } from 'contexts'

import Input from './Input'
import Result from './Result'
import Random from './Random'

export const BASE_PATH = '/conduct'

export const Conduct: React.FC = () => {
  return (
    <MySwitch>
      <Route
        exact
        path={`${BASE_PATH}/:attend_id/execute`}
        component={InputPage}
      />
      <Route
        exact
        path={`${BASE_PATH}/:attend_id/result`}
        component={ResultPage}
      />
      <Route
        exact
        path={`${BASE_PATH}/:exam_id/random`}
        component={RandomPage}
      />
    </MySwitch>
  )
}

export default Conduct

type PathParams = {
  attend_id: string
}

export const InputPage: React.FC = () => {
  const params = useParams<PathParams>()
  const { attend_id } = params

  return (
    <ToolbarProvider>
      <Input attend_id={attend_id} />
    </ToolbarProvider>
  )
}

export const ResultPage: React.FC = () => {
  const params = useParams<PathParams>()
  const { attend_id } = params

  return (
    <ToolbarProvider>
      <Result attend_id={attend_id} />
    </ToolbarProvider>
  )
}

export const RandomPage: React.FC = () => {
  const params = useParams<{ exam_id: string }>()
  const { exam_id } = params

  return (
    <ToolbarProvider>
      <Random exam_id={exam_id} />
    </ToolbarProvider>
  )
}
