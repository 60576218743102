import React, {
  useState,
  useEffect,
  PropsWithChildren,
  ReactElement,
  useRef,
  useMemo,
} from 'react'
import _ from 'lodash'
import { Ref } from 'semantic-ui-react'
import { Standard, StandardProps } from './Standard'

export type PagingProps<_T> = StandardProps

const initItemCount = 10
const increaseCount = 10

export const Paging = <T,>(
  props: PropsWithChildren<PagingProps<T>>
): ReactElement | null => {
  const ref = useRef<HTMLElement>(null)
  const { options, ...rest } = props

  const [pagingCount, setPagingCount] = useState(0)

  // watch dropdown list scrolling for paging
  const onScroll = (e: any) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target as HTMLElement

    if (clientHeight + scrollTop >= scrollHeight - 2)
      setPagingCount(count => count + 1)
  }

  useEffect(() => {
    const target = ref.current?.querySelectorAll('.menu.transition.scrolling')

    target?.[0]?.addEventListener('scroll', onScroll)

    return () => {
      target?.[0]?.removeEventListener('scroll', onScroll)
    }
  }, [ref])

  const currentItemCount = useMemo(() => {
    return initItemCount + increaseCount * pagingCount
  }, [pagingCount])

  const filteredOptions = useMemo(() => _.slice(options, 0, currentItemCount), [
    options,
    currentItemCount,
  ])

  return (
    <>
      <FDropdownStandard
        {...rest}
        ref={ref}
        options={filteredOptions}
        scrolling
      />
    </>
  )
}

const FDropdownStandard = React.forwardRef<HTMLElement, StandardProps>(
  (props, ref) => {
    return (
      <Ref innerRef={ref}>
        <Standard {...props} />
      </Ref>
    )
  }
)

FDropdownStandard.displayName = 'DropdownStandard'

export default Paging
