import React, { useState } from 'react'
import _ from 'lodash'
import { Message, Segment } from 'semantic-ui-react'
import { Selection } from 'types'

const SelectionItem: React.FC<{
  selection: Selection
  selectedList: (number | undefined)[]
  setSelectedList?: React.Dispatch<React.SetStateAction<number[]>>
  resultMode?: boolean
}> = props => {
  const { selection, selectedList, setSelectedList, resultMode } = props

  const [selected, setSelected] = useState<boolean>(
    selection.id && selectedList.indexOf(selection.id) >= 0 ? true : false
  )

  return (
    <Message
      positive={
        resultMode
          ? selection.correct && selection.correct === selected
          : selected
      }
      negative={resultMode && selection.correct !== selected}
      icon={selected ? 'check' : 'minus'}
      onClick={() => {
        if (resultMode) return
        setSelected(state => !state)
        setSelectedList &&
          setSelectedList(state =>
            selection.id
              ? selected
                ? _.pull(state, selection.id)
                : _.union(state, [selection.id])
              : state
          )
      }}
      content={
        <>
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            {selection.text || 'no text'}
          </pre>

          {resultMode && (
            <Segment>
              <pre style={{ whiteSpace: 'pre-wrap' }}>
                {_.isEmpty(selection.comment) ? '解説なし' : selection.comment}
              </pre>
            </Segment>
          )}
        </>
      }
    />
  )
}

export default SelectionItem
