import moment from 'moment'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import message from 'base/message'

export const ymd = (value?: string): boolean | string => {
  if (!value) return true
  const plain = value.toString().replace(/\//g, '')
  return plain.match(/[0-9]{8}/) && moment(plain, 'YYYYMMDD').isValid()
    ? true
    : message('validate.ymd')
}

export const year = (value?: string): boolean | string =>
  !value
    ? true
    : /[0-9]{4}/.test(value) && moment(value, 'YYYY').isValid()
    ? true
    : message('validate.year')

export const ym = (value?: string): boolean | string =>
  !value
    ? true
    : /[0-9]{6}/.test(value.replace(/\u002f/g, '')) &&
      moment(value.replace(/\u002f/g, ''), 'YYYYMM').isValid()
    ? true
    : message('validate.ym')

export const hm = (value?: string): boolean | string =>
  !value
    ? true
    : /[0-9]{4}/.test(value.replace(/\u003a/g, '')) &&
      moment(value.replace(/\u003a/g, ''), 'HHmm').isValid()
    ? true
    : message('validate.hm')

export const ymdhm = (value?: string): boolean | string =>
  !value
    ? true
    : /[0-9]{12}/.test(value.replace(/[\u002f\u003a\u0020]/g, '')) &&
      moment(
        value.replace(/[\u002f\u003a\u0020]/g, ''),
        'YYYYMMDDHHmm'
      ).isValid()
    ? true
    : message('validate.ymdhm')

export const ymdhms = (value?: string): boolean | string =>
  !value
    ? true
    : /[0-9]{14}/.test(value.replace(/[\u002f\u003a\u0020]/g, '')) &&
      moment(
        value.replace(/[\u002f\u003a\u0020]/g, ''),
        'YYYYMMDDHHmmss'
      ).isValid()
    ? true
    : message('validate.ymdhms')

export const int = (value?: string): string | boolean =>
  !value ? true : /^([1-9]\d*|0)$/.test(value) ? true : message('validate.int')

export const signedInt = (value?: string): string | boolean =>
  !value
    ? true
    : /^[-]?([1-9]\d*|0)$/.test(value)
    ? true
    : message('validate.signedInt')

export const intZeroPadding = (value?: string): string | boolean =>
  !value
    ? true
    : /^[0-9]*$/.test(value)
    ? true
    : message('validate.intZeroPadding')

export const signedIntZeroPadding = (value?: string): string | boolean =>
  !value
    ? true
    : /^[-]?\d*$/.test(value)
    ? true
    : message('validate.signedIntZeroPadding')

export const numeric = (value?: string): string | boolean =>
  !value
    ? true
    : /^([1-9]\d*|0)(\.\d+)?$/.test(value)
    ? true
    : message('validate.numeric')

export const signedNumeric = (value?: string): string | boolean =>
  !value
    ? true
    : /^[-]?([1-9]\d*|0)(\.\d+)?$/.test(value)
    ? true
    : message('validate.signedNumeric')

export const numericZeroPadding = (value?: string): string | boolean =>
  !value
    ? true
    : /^\d*([1-9]\d*|0)(\.\d+)?$/.test(value)
    ? true
    : message('validate.numericZeroPadding')

export const signedNumericZeroPadding = (value?: string): string | boolean =>
  !value
    ? true
    : /^[-]?\d*([1-9]\d*|0)(\.\d+)?$/.test(value)
    ? true
    : message('validate.signedNumericZeroPadding')

export const kana = (value?: string): string | boolean =>
  !value ? true : /^[ァ-ヴ ]*$/.test(value) ? true : message('validate.kana')

export const halfKana = (value?: string): string | boolean =>
  !value
    ? true
    : /^[\uff66-\uff9f ]*$/.test(value)
    ? true
    : message('validate.halfKana')

export const money = (value?: string): string | boolean =>
  !value
    ? true
    : /^[-]?([1-9]\d*|0)$/.test(value.replace(/,/g, ''))
    ? true
    : message('validate.money')

export const phone = (value?: string): boolean | string => {
  const m = message('validate.phone')
  if (!value) return true
  if (value.match(/^[1-9-]+$/)) return m
  const phone = parsePhoneNumberFromString(value, 'JP')
  if (!phone) return m
  return phone.isValid() ? true : m
}

export const postal = (value?: string): boolean | string =>
  !value
    ? true
    : /^[0-9]{7}$/.test(value.replace(/-/g, ''))
    ? true
    : message('validate.postal')

export const email = (value?: string): boolean | string =>
  !value
    ? true
    : /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        value
      )
    ? true
    : message('validate.email')

export const url = (value?: string): boolean | string =>
  !value
    ? true
    : /^https?:\/\/[\w!?/+\-_~:;.,*&@#$%()'[\]]+$/.test(value)
    ? true
    : message('validate.url')

export const prohibit = (value?: string): boolean | string =>
  !value
    ? true
    : !/"/.test(value) &&
      !/'/.test(value) &&
      !/</.test(value) &&
      !/>/.test(value) &&
      !/&/.test(value)
    ? true
    : message('validate.prohibit')
