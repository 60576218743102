import React, { useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import _ from 'lodash'
import { useUser } from 'base/authenticate'
import { MenuItems, RightBlockProps, roleFilter } from '.'
import MenuItemList from './MenuItemList'

export type MenuBlockProps = {
  menuItems: MenuItems | null
} & RightBlockProps

export const MenuBlock: React.FC<MenuBlockProps> = props => {
  const { menuItems } = props
  const { user } = useUser()

  const filteredMenuItems = useMemo(() => roleFilter(menuItems, user?.roles), [
    menuItems,
    user?.roles,
  ])

  return (
    <>
      {_(filteredMenuItems)
        .map((menuItem, i) => (
          <Grid.Column key={i}>
            <MenuItemList {...props} menuItems={[menuItem]} subMenu={false} />
          </Grid.Column>
        ))
        .value()}
    </>
  )
}

export default MenuBlock
