import React from 'react'
import {
  Controller,
  Control,
  ValidationRule,
  FieldErrors,
} from 'react-hook-form'
import { TextAreaProps, TextArea } from 'semantic-ui-react'
import ErrorMessageLabel from 'base/control/ErrorLabel'
import message from 'base/message'

export type StandardProps = {
  control: Control
  errors: FieldErrors
  name: string
  title?: string
  defaultValue?: unknown
  editValue?: (value: string) => string | null
  autoComplete?: string
  required?: boolean
  min?: number
  max?: number
  minLength?: number
  maxLength?: number
  pattern?: ValidationRule<RegExp>
  validate?: Record<string, (value: unknown) => boolean>
} & TextAreaProps

export const Standard: React.FC<StandardProps> = props => {
  const {
    control,
    errors,
    name,
    required,
    defaultValue,
    editValue,
    autoComplete,
    validate,
    min,
    max,
    minLength,
    maxLength,
    pattern,
    onChange: _onChange,
    ...rest
  } = props

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{
          required: required ? message('validate.required') : undefined,
          min: min
            ? { value: min, message: message('validate.min', { min }) }
            : undefined,
          max: max
            ? { value: max, message: message('validate.max', { max }) }
            : undefined,
          minLength: minLength
            ? {
                value: minLength,
                message: message('validate.minLength', { minLength }),
              }
            : undefined,
          maxLength: maxLength
            ? {
                value: maxLength,
                message: message('validate.maxLength', { maxLength }),
              }
            : undefined,
          pattern,
          validate: validate ? validate : () => true,
        }}
        defaultValue={defaultValue}
        render={({ onChange, onBlur, value, name }) => (
          <TextArea
            onBlur={onBlur}
            onChange={(e, data) => {
              onChange(editValue ? editValue(e.target.value) : e.target.value)
              _onChange && _onChange(e, data)
            }}
            value={value || defaultValue}
            name={name}
            autoComplete={autoComplete || 'off'}
            {...rest}
          />
        )}
      />

      <ErrorMessageLabel errors={errors} name={name} />
    </>
  )
}

Standard.defaultProps = {
  required: false,
  defaultValue: '',
}

export default Standard
