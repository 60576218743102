import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useToolbarDispatch } from 'contexts'
import _ from 'lodash'
import { Grid, Form, Button } from 'semantic-ui-react'
import { useAxios } from 'contexts'
import { User } from 'types'
import Textbox from 'component/control/textbox'
import Dropdown from 'component/control/dropdown'
import Layout from 'component/layout'

type Props =
  | { mode: 'new'; user_id?: string }
  | { mode: 'view' | 'edit' | 'delete'; user_id?: string }

type Inputs = Omit<User, 'id'>

const InputForm: React.FC<Props> = props => {
  const { mode, user_id } = props

  const SELF_PATH = `/crud/user`
  const BASE_PATH = `/crud/user`

  const locked = mode === 'view' || mode === 'delete' ? true : false

  const { axios } = useAxios()
  const history = useHistory()
  const toolbarDispatch = useToolbarDispatch()

  const { handleSubmit, errors, control, setValue } = useForm<Inputs>({
    criteriaMode: 'all',
  })

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    toolbarDispatch({
      type: 'SET',
      title: 'ユーザ',
    })
  }, [])

  const onSubmit = (model: Inputs) => {
    switch (mode) {
      case 'new':
        postApi(model)
        break
      case 'edit':
        patchApi(model)
        break
      case 'delete':
        deleteApi()
        break
    }
  }

  useEffect(() => {
    const getUser = async () => {
      setLoading(true)
      const { data, error } = await axios.get<User>(`${BASE_PATH}/${user_id}`)
      setLoading(false)
      if (error) {
        console.log('get error', error)
        return
      }

      const { name, email, available, gender, sub, birth_date, money, rate } =
        data || {}
      setValue('name', name, { shouldValidate: true })
      setValue('email', email, { shouldValidate: true })
      setValue('available', available, { shouldValidate: true })
      setValue('gender', gender, { shouldValidate: true })
      setValue('sub', sub, { shouldValidate: true })
      setValue('birth_date', birth_date, { shouldValidate: true })
      setValue('money', money, { shouldValidate: true })
      setValue('rate', rate, { shouldValidate: true })
    }

    if (mode !== 'new') getUser()
  }, [mode, user_id, setValue])

  const postApi = async (model: Inputs) => {
    setLoading(true)
    const { data, error } = await axios.post<User>(`${SELF_PATH}`, model)
    setLoading(false)

    if (error) {
      console.log('post error', error)
      return
    }
    console.log('post data:', data)
    history.push(BASE_PATH)
  }

  const patchApi = async (model: Inputs) => {
    setLoading(true)
    const { data, error } = await axios.patch<User>(
      `${SELF_PATH}/${user_id}`,
      model
    )
    setLoading(false)

    if (error) {
      console.log('patch error', error)
      return
    }
    console.log('patch data:', data)
    history.push(BASE_PATH)
  }

  const deleteApi = async () => {
    setLoading(true)
    const { data, error } = await axios.delete<User>(`${SELF_PATH}/${user_id}`)
    setLoading(false)

    if (error) {
      console.log('delete error', error)
      return
    }
    console.log('delete data:', data)
    history.push(BASE_PATH)
  }

  return (
    <Layout.Page title='ユーザ' loading={loading}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Field
                required
                error={errors.name ? true : false}
                disabled={locked}
              >
                <label>name</label>
                <Textbox.Base.Standard
                  control={control}
                  errors={errors}
                  name='name'
                  placeholder='enter name'
                  required
                />
              </Form.Field>
              <Form.Field
                required
                error={errors.email ? true : false}
                disabled={locked}
              >
                <label>email</label>
                <Textbox.Base.Email
                  control={control}
                  errors={errors}
                  name='email'
                  placeholder='enter email address'
                />
              </Form.Field>

              <Form.Field
                required
                error={errors.sub ? true : false}
                disabled={locked}
              >
                <label>sub</label>
                <Textbox.Base.Standard
                  control={control}
                  errors={errors}
                  name='sub'
                  placeholder='enter sub'
                  required
                />
              </Form.Field>

              <Form.Field
                required
                error={errors.gender ? true : false}
                disabled={locked}
              >
                <label>gender</label>
                <Dropdown.Gender
                  control={control}
                  errors={errors}
                  name='gender'
                  disabled={locked}
                  search
                  selection
                />
              </Form.Field>

              {mode !== 'view' && <Button type='submit' content='Submit' />}
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Layout.Page>
  )
}

export default InputForm
