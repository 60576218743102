export const getPathname = (uri?: string): string | undefined => {
  if (!uri) return undefined

  try {
    const plain =
      uri.indexOf('{') > 0 ? uri.substring(0, uri.indexOf('{')) : uri
    const edited = /^https?:\/\/[\w!?/+\-_~;:.,*&@#$%()'[\]]+$/.test(plain)
      ? new URL(plain).pathname
      : plain
    return edited
  } catch (error) {
    return undefined
  }
}

export default {
  getPathname,
}
