import React from 'react'
import _ from 'lodash'
import { Controller, Control, FieldErrors } from 'react-hook-form'
import {
  DropdownProps,
  Dropdown,
  DropdownItemProps,
  Loader,
} from 'semantic-ui-react'
import ErrorMessageLabel from 'base/control/ErrorLabel'
import message from 'base/message'

export type StandardProps = {
  control: Control
  errors: FieldErrors
  name: string
  title?: string
  defaultValue?: unknown
  loading?: boolean
  SearchBlock?: React.ReactElement
  required?: boolean
  min?: number
  max?: number
  validate?: Record<string, (value: unknown) => boolean>
} & DropdownProps

const unSelectedOption: DropdownItemProps = {
  key: 'uselected',
  text: '',
  value: '',
}

export const Standard: React.FC<StandardProps> = props => {
  const {
    control,
    errors,
    name,
    required,
    defaultValue,
    SearchBlock,
    loading,
    min,
    max,
    validate,

    options,
    multiple,
    scrolling,
    selection,
    onChange: _onChange,
    ...rest
  } = props

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{
          required: required ? message('validate.required') : undefined,
          min: min
            ? { value: min, message: message('validate.min', { min }) }
            : undefined,
          max: max
            ? { value: max, message: message('validate.max', { max }) }
            : undefined,
          validate: _.assign({}, validate, {
            requiredMultiple: (value: unknown) => {
              if (!multiple || !required) return true
              if (!_.isArray(value)) return true
              return value.length > 0 ? true : message('validate.required')
            },
          }),
        }}
        defaultValue={multiple ? [] : defaultValue}
        render={({ onChange, onBlur, value, name }) =>
          selection ? (
            <Dropdown
              onBlur={onBlur}
              onChange={(e, data) => {
                const { value } = data
                onChange(value)
                _onChange && _onChange(e, data)
              }}
              value={multiple && !_.isArray(value) ? [value] : value}
              name={name}
              options={
                multiple ? options : _.union([unSelectedOption], options)
              }
              multiple={multiple}
              selection={selection}
              loading={loading}
              {...rest}
            />
          ) : (
            <Dropdown name={name} multiple={multiple} {...rest}>
              <Dropdown.Menu style={{ width: '100%' }}>
                {SearchBlock && SearchBlock}
                <Dropdown.Menu scrolling={scrolling}>
                  {loading && <Loader active />}

                  {(multiple
                    ? options
                    : _.union([unSelectedOption], options)
                  )?.map((option, i: number) => (
                    <Dropdown.Item
                      key={i}
                      {...option}
                      onClick={() => {
                        onChange(
                          multiple
                            ? _.union(value, [option.value])
                            : option.value
                        )
                      }}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
          )
        }
      />

      <ErrorMessageLabel errors={errors} name={name} />
    </>
  )
}

Standard.defaultProps = {
  required: false,
  defaultValue: '',
}

export default Standard
