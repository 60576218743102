import React, {
  PropsWithChildren,
  ReactElement,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import _ from 'lodash'
import { Animate } from 'react-simple-animate'
import {
  Icon,
  Pagination,
  PaginationProps,
  Segment,
  Menu,
  Popup,
} from 'semantic-ui-react'

import { useLocalStorage } from 'base/hook'
import StickyTable from 'base/piece/StickyTable'
import { DataTableType } from '.'
import PaginateLabel from './PaginateLabel'
import PageLimitSelector from './PageLimitSelector'
import FilterBox from './FilterBox'
import DownloadCsvButton from './DownloadCsvButton'

import './style.css'

type PaginateOption = {
  pageLimit: number
  filterText: string
}

type CellOption = {
  cellIndex: number
  width?: number
}

export const BasicDataTable = <T, C>(
  props: PropsWithChildren<DataTableType.BasicDataTableProps<T, C>>
): ReactElement<any, any> | null => {
  const {
    dtid,
    dataList,
    ConditionBlock,
    HeaderBlock,
    RowBlock,
    downloadFilename,
    adjustWidth,
    onSelectedChange,
    loading,
    defaultSearchOpen,
    ...rest
  } = props

  const [activePage, setActivePage] = useState(0)
  const [selectedRows, setSelectedRows] = useState<T[]>([])
  const [showSearchMenu, setShowSearchMenu] = useState(
    defaultSearchOpen ? true : false
  )

  const [paginateOption, setPaginateOption] = useLocalStorage<PaginateOption>({
    dbkey: 'datatable',
    key: `${dtid}-pagenateOption`,
    initial: {
      pageLimit: 25,
      filterText: '',
    },
  })

  const [cellOptions, setCellOptions] = useLocalStorage<CellOption[]>({
    dbkey: 'datatable',
    key: `${dtid}-cellOptions`,
    initial: _.times(100, i => ({ cellIndex: i })),
  })

  // for row select action
  useEffect(() => {
    selectedRows && onSelectedChange && onSelectedChange(selectedRows)
  }, [selectedRows, onSelectedChange])

  // when loading(possively api read) -> reset selectedRows
  useEffect(() => {
    setSelectedRows([])
  }, [loading])

  // for exchange cells
  const exchangeOrder = useCallback(
    (source: number, target: number) => {
      setCellOptions(options => exchangeArray(options, source, target))
    },
    [setCellOptions]
  )

  const handleAllSelect = () => setSelectedRows(dataList ? dataList : [])
  const handleAllDeSelect = () => setSelectedRows([])

  // for resize cell
  const resizeCell = useCallback(
    (cellIndex: number, width: number) => {
      setCellOptions(options =>
        _(options)
          .map(option =>
            option.cellIndex === cellIndex
              ? _.assign({}, option, { width })
              : option
          )
          .value()
      )
    },
    [setCellOptions]
  )

  const renderRow = useCallback(
    Components => {
      const Row = Components.props.children
      if (!Row) return Components

      const Cells = Row.props.children
      if (!Cells) return Components

      return React.cloneElement(Row, {
        ...Row.props,
        children: _(cellOptions)
          .map((option, i) =>
            Cells[option.cellIndex]
              ? React.cloneElement(Cells[option.cellIndex], {
                  ...Cells[option.cellIndex].props,
                  key: i,
                  index: option.cellIndex,
                  defaultWidth:
                    option.width ||
                    Cells[option.cellIndex].props.defaultWidth ||
                    undefined,
                })
              : null
          )
          .value(),
      })
    },
    [cellOptions]
  )

  // for paging and filter
  const { pageLimit, filterText } = paginateOption

  const totalPages = useMemo(
    () => (dataList ? Math.ceil(dataList.length / pageLimit) : 0),
    [dataList, pageLimit]
  )

  const substanceActivePage = useMemo(
    () => (activePage > totalPages ? totalPages : activePage),
    [activePage, totalPages]
  )

  const start = useMemo(
    () =>
      (substanceActivePage - 1) * pageLimit < 0
        ? 0
        : (substanceActivePage - 1) * pageLimit,
    [substanceActivePage, pageLimit]
  )

  const end = useMemo(() => start + pageLimit, [start, pageLimit])

  const onPageChange = (_e: React.MouseEvent, param: PaginationProps) => {
    const { activePage } = param
    if (!_.isNumber(activePage)) return
    setActivePage(activePage)
  }

  const onPageLimitChange = (value: number) => {
    setPaginateOption(state => _.assign({}, state, { pageLimit: value }))
  }

  const onFilterTextChange = (value: string) => {
    setPaginateOption(state => _.assign({}, state, { filterText: value }))
  }

  const onePageDataList = useMemo(
    () =>
      _(dataList)
        .filter(data =>
          _.find<any>(data, value => _.includes(value, filterText))
        )
        .slice(start, end)
        .value(),
    [dataList, filterText, start, end]
  )

  const rows = useMemo(
    () =>
      _(onePageDataList)
        .map((data, i) => (
          <RowBlock
            key={i}
            data={data}
            renderRow={renderRow}
            handleClick={() =>
              setSelectedRows(selectedRows =>
                _(selectedRows).find(row => _.isEqual(row, data))
                  ? _(selectedRows)
                      .filter(row => !_.isEqual(row, data))
                      .value()
                  : _.concat(selectedRows, [data])
              )
            }
            selected={
              _(selectedRows).find(row => _.isEqual(row, data)) ? true : false
            }
          />
        ))
        .value(),
    [onePageDataList, renderRow, selectedRows]
  )

  return (
    <>
      <Animate
        play={showSearchMenu}
        start={{ opacity: 0, filter: 'blur(10px)', height: 0 }}
        end={{ opacity: 1, filter: 'blur(0)', height: 'auto' }}
      >
        {ConditionBlock && <ConditionBlock />}
      </Animate>

      <Menu attached icon compact borderless stackable>
        {ConditionBlock && (
          <Popup
            content='検索'
            mouseEnterDelay={500}
            trigger={
              <Menu.Item
                link
                onClick={() => setShowSearchMenu(state => !state)}
              >
                <Icon name={showSearchMenu ? 'search minus' : 'search'} />
              </Menu.Item>
            }
          />
        )}
        <Popup
          content='全選択'
          mouseEnterDelay={500}
          trigger={
            <Menu.Item link onClick={handleAllSelect}>
              <Icon name='check circle' />
            </Menu.Item>
          }
        />
        <Popup
          content='全選択解除'
          mouseEnterDelay={500}
          trigger={
            <Menu.Item link onClick={handleAllDeSelect}>
              <Icon name='check circle outline' />
            </Menu.Item>
          }
        />
        <Menu.Item>
          <FilterBox filterText={filterText} onChange={onFilterTextChange} />
        </Menu.Item>
        <Menu.Item>
          <PageLimitSelector
            limit={pageLimit}
            onLimitChange={onPageLimitChange}
          />
          <PaginateLabel
            total={dataList ? dataList.length : 0}
            start={start + 1}
            end={end}
          />
        </Menu.Item>
        <Menu.Item position='right'>
          <Pagination
            activePage={substanceActivePage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            boundaryRange={0}
            siblingRange={0}
            pointing
            size='small'
          />
        </Menu.Item>
      </Menu>

      <Segment attached loading={loading} style={{ padding: 0 }}>
        <StickyTable
          style={{ width: adjustWidth ? '100%' : 'fit-content' }}
          selectable
          celled
          compact
          className='datatable'
          {...rest}
        >
          <StickyTable.Header>
            <HeaderBlock
              dtid={dtid}
              renderRow={renderRow}
              exchangeOrder={exchangeOrder}
              resizeCell={resizeCell}
            />
          </StickyTable.Header>

          <StickyTable.Body>{rows}</StickyTable.Body>
        </StickyTable>
      </Segment>
      <Menu attached='bottom' icon compact borderless stackable>
        <Menu.Item>
          <DownloadCsvButton<T>
            data={onePageDataList}
            originalData={dataList ? dataList : []}
            downloadFileName={downloadFilename || 'data.csv'}
          />
        </Menu.Item>
      </Menu>
    </>
  )
}

const exchangeArray = (array: any[], from: number, to: number) => {
  const arr = _.cloneDeep(array)
  arr.splice(from, 1)
  arr.splice(from, 0, array[to])
  arr.splice(to, 1)
  arr.splice(to, 0, array[from])
  return arr
}

export default BasicDataTable
