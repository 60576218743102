import SelectionItem from 'component/piece/SelectionItem'
import { useAxios } from 'contexts'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Loader } from 'semantic-ui-react'
import { HalResource, Selection } from 'types'

const Selections: React.FC<{
  question_id: number
  selectedList: (number | undefined)[]
  setSelectedList?: React.Dispatch<React.SetStateAction<number[]>>
  resultMode?: boolean
}> = props => {
  const { question_id, selectedList, setSelectedList, resultMode } = props

  const { axios } = useAxios()
  const [loading, setLoading] = useState(false)
  const [selections, setSelections] = useState<Selection[]>()

  useEffect(() => {
    const forward = async () => {
      setLoading(true)
      const { data, error } = await axios.get<HalResource<Selection>>(
        `/crud/question/${question_id}/selections`
      )
      setLoading(false)
      if (error) return

      const list = data?._embedded?.[_.keys(data._embedded)[0]]
      setSelections(list)
    }

    forward()
  }, [question_id])

  return (
    <>
      {loading ? (
        <Loader active />
      ) : (
        _(selections)
          .orderBy(['id'], ['asc'])
          .map(selection => (
            <SelectionItem
              key={selection.id}
              selection={selection}
              selectedList={selectedList}
              setSelectedList={setSelectedList}
              resultMode={resultMode}
            />
          ))
          .value()
      )}
    </>
  )
}

export default Selections
