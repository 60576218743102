import React from 'react'

import { NumberBase } from './NumberBase'

export type NumericProps = React.ComponentProps<typeof NumberBase>

export const Numeric: React.FC<NumericProps> = props => (
  <NumberBase {...props} decimal />
)

export default Numeric
