import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useState,
  useEffect,
} from 'react'
import { Menu, Popup, Segment, Icon } from 'semantic-ui-react'
import DataTable, { DataTableType } from 'base/piece/DataTable'
import _ from 'lodash'
import { useAxios } from 'contexts'
import { HalResource } from 'types'

type ConditionDataTableProps<T, C> = DataTableType.DataTableProps<T, C> & {
  ConditionBlock: React.FC<DataTableType.ConditionBlockProps<C>>
  url: string
  dataList?: never
  defaultCondition?: C
}

export const ConditionDataTable = <T, C>(
  props: PropsWithChildren<ConditionDataTableProps<T, C>>
): ReactElement<any, any> | null => {
  const { ConditionBlock, url, defaultCondition, ...rest } = props

  const { axios } = useAxios()
  const [loading, setLoading] = useState(false)
  const [condition, setCondition] = useState<C | null>(
    defaultCondition ? defaultCondition : null
  )
  const [dataList, setDataList] = useState<T[]>([])

  const WrappedConditionBlock = useCallback(
    props => (
      <ConditionBlock
        {...props}
        condition={condition}
        setCondition={setCondition}
        defaultCondition={defaultCondition}
      />
    ),
    [condition, setCondition, defaultCondition]
  )

  useEffect(() => {
    const getDataList = async () => {
      setLoading(true)
      const { data, error } = await axios.get<HalResource<T>>(url, {
        params: condition,
      })
      setLoading(false)

      if (error) {
        console.log(error)
        return
      }

      const list = data?._embedded?.[_.keys(data._embedded)[0]]

      setDataList(list || [])
    }

    getDataList()
  }, [condition, url])

  return (
    <DataTable<T, C>
      {...rest}
      ConditionBlock={WrappedConditionBlock}
      dataList={dataList}
      loading={loading}
      defaultSearchOpen={defaultCondition ? true : false}
    />
  )
}

export default ConditionDataTable

type ConditionPanelProps<C> = DataTableType.ConditionBlockProps<C> & {
  handleRefresh: (...args: any) => void
  handleReset: (...args: any) => void
  ConditionForm: ReactElement
}

export const ConditionPanel = <C,>(
  props: PropsWithChildren<ConditionPanelProps<C>>
): ReactElement<any, any> | null => {
  const { handleRefresh, handleReset, ConditionForm } = props

  return (
    <>
      <Menu attached icon compact borderless stackable inverted color='grey'>
        <Menu.Item name='Filter' />
        <Popup
          content='再検索'
          mouseEnterDelay={500}
          trigger={
            <Menu.Item link onClick={handleRefresh}>
              <Icon name='refresh' />
            </Menu.Item>
          }
        />
        <Popup
          content='リセット'
          mouseEnterDelay={500}
          trigger={
            <Menu.Item link onClick={handleReset}>
              <Icon name='erase' />
            </Menu.Item>
          }
        />
      </Menu>
      <Segment attached>{ConditionForm}</Segment>
    </>
  )
}
