import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { Button, Form, Menu } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import { useToolbarDispatch } from 'contexts'
import {
  ConditionDataTable,
  ConditionPanel,
  DataTableType,
  HeaderRow,
  HeaderCell,
  BodyRow,
  BodyCell,
} from 'base/piece/DataTable'
import Textbox from 'component/control/textbox'
import Layout from 'component/layout'
import { Plactice } from 'types'

type ConditionType = {
  name: string
}

type Props = {
  exam_id?: string
}

export const List: React.FC<Props> = props => {
  const { exam_id } = props

  const history = useHistory()
  const toolbarDispatch = useToolbarDispatch()

  const [targetId, setTargetId] = useState<number | null>(null)

  const BASE_PATH = exam_id
    ? `/crud/exam/${exam_id}/plactices`
    : `/crud/plactice`

  const handleSelectedChange = (selectedRows: Plactice[]) => {
    selectedRows.length === 1
      ? setTargetId(selectedRows[0].id || null)
      : setTargetId(null)
  }

  useEffect(() => {
    toolbarDispatch({
      type: 'SET',
      title: '練習問題一覧',
      menuItems: (
        <Menu.Item>
          <Button.Group>
            <Button
              icon='eye'
              content='詳細'
              disabled={!targetId}
              onClick={() => history.push(`${BASE_PATH}/${targetId}/view`)}
            />
            <Button
              icon='plus'
              content='新規'
              onClick={() => history.push(`${BASE_PATH}/new`)}
            />
            <Button
              icon='pencil'
              content='編集'
              disabled={!targetId}
              onClick={() => history.push(`${BASE_PATH}/${targetId}/edit`)}
            />
            <Button
              icon='eraser'
              content='削除'
              disabled={!targetId}
              onClick={() => history.push(`${BASE_PATH}/${targetId}/delete`)}
            />
          </Button.Group>
        </Menu.Item>
      ),
    })
  }, [targetId, history, BASE_PATH])

  return (
    <Layout.Page basic>
      <ConditionDataTable<Plactice, ConditionType>
        dtid='plactice-datatable'
        url={`${BASE_PATH}`}
        ConditionBlock={ConditionBlock}
        HeaderBlock={HeaderBlock}
        RowBlock={RowBlock}
        sortable
        onSelectedChange={handleSelectedChange}
      />
    </Layout.Page>
  )
}

const ConditionBlock: React.FC<
  DataTableType.ConditionBlockProps<ConditionType>
> = props => {
  const { condition, setCondition, defaultCondition } = props

  const {
    handleSubmit,
    errors,
    control,
    setValue,
    reset,
  } = useForm<ConditionType>()

  useEffect(() => {
    setValue('name', condition?.name || '')
  }, [condition, setValue])

  const onSubmit = (model: ConditionType) => {
    if (!setCondition) return
    setCondition(state => _.assign({}, state, model))
  }

  const handleReset = () => {
    reset(defaultCondition)
    setCondition && setCondition(defaultCondition)
  }

  return (
    <ConditionPanel
      handleRefresh={handleSubmit(onSubmit)}
      handleReset={handleReset}
      ConditionForm={
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Field error={errors.name ? true : false} width={4}>
            <Textbox.Base.Standard
              label='name'
              control={control}
              errors={errors}
              name='name'
              placeholder='enter user name'
            />
          </Form.Field>
        </Form>
      }
    />
  )
}

const HeaderBlock: React.FC<DataTableType.HeaderBlockProps> = props => {
  const { renderRow, ...rest } = props

  return renderRow(
    <>
      <HeaderRow>
        <HeaderCell name='id' defaultWidth={90} {...rest}>
          <span>id</span>
        </HeaderCell>
        <HeaderCell name='name' defaultWidth={90} {...rest}>
          <span>name</span>
        </HeaderCell>
        <HeaderCell name='link' defaultWidth={90} {...rest}>
          <span>link</span>
        </HeaderCell>
      </HeaderRow>
    </>
  )
}

type RowBlockProps = {
  data: Plactice
} & DataTableType.BodyBlockProps

const RowBlock: React.FC<RowBlockProps> = props => {
  const { data, renderRow, selected, handleClick } = props

  const { id, name } = data

  return renderRow(
    <>
      <BodyRow positive={selected} onClick={handleClick}>
        <BodyCell content={id} />
        <BodyCell content={name} />
        <BodyCell content={''} />
      </BodyRow>
    </>
  )
}

export default List
