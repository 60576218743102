import React, {
  useState,
  useEffect,
  PropsWithChildren,
  ReactElement,
} from 'react'
import { DropdownItemProps } from 'semantic-ui-react'
import _ from 'lodash'
import { StandardProps } from './Standard'
import { useAxios } from 'contexts'
import { HalResource } from 'types'
import Search from './Search'

export type WithApiProps<T> = {
  url: string
  editOptions: (option: T[]) => DropdownItemProps[] | undefined
} & StandardProps

export const WithApi = <T,>(
  props: PropsWithChildren<WithApiProps<T>>
): ReactElement<any, any> | null => {
  const { url, editOptions, ...rest } = props

  const { axios } = useAxios()
  const [loading, setLoading] = useState<boolean>(false)
  const [dataList, setDataList] = useState<T[]>([])
  const [opened, setOpened] = useState<boolean>(false)

  useEffect(() => {
    const getList = async () => {
      if (!url || !opened) {
        setDataList([])
        return
      }

      setLoading(true)
      const { data, error } = await axios.get<HalResource<T>>(url)
      setLoading(false)

      if (error) return
      const list = data?._embedded?.[_.keys(data._embedded)[0]]
      setDataList(list || [])
    }
    getList()
  }, [url, opened])

  return (
    <Search
      {...rest}
      className='withApi'
      options={editOptions(dataList)}
      loading={loading}
      onOpen={() => setOpened(true)}
    />
  )
}

export default WithApi
