import React, { useState, useContext } from 'react'
import { Image, ImageProps } from 'semantic-ui-react'
import { useUser } from 'base/authenticate'
import { getConfigGlobal } from 'config'

const Avatar: React.FC<ImageProps> = props => {
  const { avatarEndpoint } = getConfigGlobal()

  const { user } = useUser()

  const [imageLoadFailed, setImageLoadFailed] = useState<boolean>(false)

  const handleOnError = (_e: React.SyntheticEvent<HTMLImageElement, Event>) =>
    setImageLoadFailed(true)

  return (
    <>
      {!user?.sub || imageLoadFailed ? (
        <Image {...props} src={'/images/noimage.png'} />
      ) : (
        <Image
          {...props}
          src={`${avatarEndpoint}/${user?.sub}`}
          onError={handleOnError}
        />
      )}
    </>
  )
}

export default Avatar
