import React from 'react'
import { Route, useParams } from 'react-router-dom'
import { MySwitch } from 'base/router'
import { ToolbarProvider } from 'contexts'
import List from './List'
import InputForm from './InputForm'

export const BASE_PATH = '/crud/user'

export const User: React.FC = () => {
  return (
    <MySwitch>
      <Route exact path={`${BASE_PATH}`} component={Top} />
      <Route exact path={`${BASE_PATH}/new`} component={New} />
      <Route exact path={`${BASE_PATH}/:user_id/view`} component={View} />
      <Route exact path={`${BASE_PATH}/:user_id/edit`} component={Edit} />
      <Route exact path={`${BASE_PATH}/:user_id/delete`} component={Delete} />
    </MySwitch>
  )
}

export default User

type PathParams = {
  user_id: string
}

export const Top: React.FC = () => (
  <ToolbarProvider>
    <List />
  </ToolbarProvider>
)

export const New: React.FC = () => (
  <ToolbarProvider>
    <InputForm mode='new' />
  </ToolbarProvider>
)

export const View: React.FC = () => {
  const params = useParams<PathParams>()
  const { user_id } = params

  return (
    <ToolbarProvider>
      <InputForm mode='view' user_id={user_id} />
    </ToolbarProvider>
  )
}

export const Edit: React.FC = () => {
  const params = useParams<PathParams>()
  const { user_id } = params

  return (
    <ToolbarProvider>
      <InputForm mode='edit' user_id={user_id} />
    </ToolbarProvider>
  )
}

export const Delete: React.FC = () => {
  const params = useParams<PathParams>()
  const { user_id } = params

  return (
    <ToolbarProvider>
      <InputForm mode='delete' user_id={user_id} />
    </ToolbarProvider>
  )
}
