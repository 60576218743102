import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Button, Divider } from 'semantic-ui-react'
import { useAxios } from 'contexts'
import { HalResource, Question } from 'types'
import Layout from 'component/layout'
import QuestionInputForm from './InputForm'

type Props = {
  plactice_id: string
}

type Add = {
  key: string
}

const BulkInput: React.FC<Props> = props => {
  const { plactice_id } = props
  const { axios } = useAxios()
  const [loading, setLoading] = useState(false)
  const [dataList, setDataList] = useState<Question[]>([])
  const [addingList, setAddingList] = useState<Add[]>([])
  const [refresh, setRefresh] = useState(0)

  const BASE_PATH = `/crud/plactice/${plactice_id}/questions`

  const handleRefresh = () => setRefresh(state => ++state)

  useEffect(() => {
    const getApi = async () => {
      setLoading(true)
      const { data, error } = await axios.get<HalResource<Question>>(
        `${BASE_PATH}`
      )
      setLoading(false)
      if (error) {
        console.log('get error', error)
        return
      }

      const list = data?._embedded?.[_.keys(data._embedded)[0]]
      setDataList(list || [])
    }

    getApi()
  }, [BASE_PATH, setDataList, refresh])

  return (
    <Layout.Page title='設問' loading={loading}>
      {_(dataList)
        .orderBy(['sequence_number'], ['asc'])
        .map(question => (
          <QuestionInputForm
            key={question.id}
            mode='edit'
            question_id={_.toString(question.id)}
            onSave={() => {
              handleRefresh()
            }}
          />
        ))
        .value()}

      {_(addingList)
        .map(data => (
          <QuestionInputForm
            key={data.key}
            mode='new'
            plactice_id={plactice_id}
            onSave={() => {
              setAddingList(list =>
                _(list)
                  .filter(elem => elem.key !== data.key)
                  .value()
              )
              handleRefresh()
            }}
            handleCancel={() =>
              setAddingList(list =>
                _(list)
                  .filter(elem => elem.key !== data.key)
                  .value()
              )
            }
          />
        ))
        .value()}

      <Divider />

      <Button
        primary
        icon='plus'
        content='Add Question'
        onClick={() =>
          setAddingList(list => [...list, { key: _.uniqueId() } as Add])
        }
      />
    </Layout.Page>
  )
}

export default BulkInput
