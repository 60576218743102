import React from 'react'
import './App.css'
import 'semantic-ui-css/semantic.min.css'
import { AuthProvider } from 'base/authenticate/keycloak'
import { AxiosProvider } from 'contexts'
import Router from './Router'

import { ToastProvider } from 'base/toast'

const App: React.FC = () => {
  return (
    <div className='App'>
      <ToastProvider>
        <AuthProvider>
          <AxiosProvider>
            <Router />
          </AxiosProvider>
        </AuthProvider>
      </ToastProvider>
    </div>
  )
}

export default App
