import React from 'react'
import _ from 'lodash'

import { Standard } from './Standard'
import * as Validations from 'base/control/validations'

export type Props = React.ComponentProps<typeof Standard>

export const Url: React.FC<Props> = props => {
  const { validate } = props

  const handleEdit = (value: string): string | null => {
    return value
  }

  return (
    <Standard
      {...props}
      type='text'
      editValue={handleEdit}
      validate={_.assign({}, validate, { url: Validations.url })}
    />
  )
}

export default Url
