import React from 'react'
import _ from 'lodash'
import { DropdownItemProps } from 'semantic-ui-react'
import { WithApi } from 'base/control/dropdown/WithApi'
import { User } from 'base/control'
import { StandardProps } from 'base/control/dropdown/Standard'

export const UserDropdown: React.FC<StandardProps> = props => {
  return (
    <WithApi<User>
      url='/crud/user'
      editOptions={items =>
        _(items)
          .map<DropdownItemProps>((item, i) => ({
            key: i,
            text: item.name,
            value: item.id,
            image: {
              avatar: true,
              src: item.profile_image,
            },
            description: item.country,
            label: { color: 'red', empty: true, circular: true },
          }))
          .value()
      }
      {...props}
    />
  )
}

UserDropdown.defaultProps = {}

export default UserDropdown
