import React from 'react'
import _ from 'lodash'
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'

import { Standard } from './Standard'
import * as Validations from 'base/control/validations'

export type PhoneProps = React.ComponentProps<typeof Standard>

export const Phone: React.FC<PhoneProps> = props => {
  const { validate } = props

  const handleEdit = (value: string): string | null => {
    if (!value) return value
    if (value.match(/^[1-9-]+$/)) return value
    const phone = parsePhoneNumberFromString(value, 'JP')
    if (!phone || !phone.isValid()) return value

    return new AsYouType('JP').input(value)
  }

  return (
    <Standard
      {...props}
      type='tel'
      editValue={handleEdit}
      validate={_.assign({}, validate, { phone: Validations.phone })}
    />
  )
}

export default Phone
