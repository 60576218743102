import React, { useCallback } from 'react'
import { Form, InputProps } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import Textbox from 'base/control/textbox'

type FilterBoxProps = {
  filterText: string
  onChange: (value: string) => void
}

type FilterBoxInputs = {
  filterText: string
}

export const FilterBox: React.FC<FilterBoxProps> = props => {
  const { filterText, onChange } = props

  const { control, setValue, errors } = useForm<FilterBoxInputs>()

  const handleOnChange = (_e: any, data: InputProps) => {
    setValue('filterText', data.value)
    delayedOnChange(data.value)
  }

  const delayedOnChange = useCallback(
    _.debounce(value => {
      onChange(value)
    }, 500),
    []
  )

  return (
    <Form
      style={{
        display: 'inline',
      }}
    >
      <Textbox.Standard
        control={control}
        errors={errors}
        name='filterText'
        icon='search'
        placeholder='filter keyword...'
        defaultValue={filterText}
        onChange={handleOnChange}
        autoComplete='off'
      />
    </Form>
  )
}

export default FilterBox
