import React, { useState, useEffect } from 'react'
import Keycloak, { KeycloakProfile } from 'keycloak-js'
import _ from 'lodash'
import {
  useKeycloak,
  ReactKeycloakProvider as KeycloakProvider,
} from '@react-keycloak/web'
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom'
import {
  Dimmer,
  Icon,
  SemanticICONS,
  Header,
  Divider,
  Button,
} from 'semantic-ui-react'
import {
  UserProvider,
  useUser,
  useUserDispatch,
} from 'base/authenticate/UserContext'
import { getConfig } from 'config'

const config = getConfig()

const keycloak: Keycloak.KeycloakInstance = Keycloak({
  url: config.auth_endpoint,
  realm: config.realm,
  clientId: config.clientId,
})

const initOptions: Keycloak.KeycloakInitOptions = {
  onLoad: 'check-sso',
  checkLoginIframe: false,
}

export const AuthProvider: React.FC = props => {
  return (
    <UserProvider>
      <KeycloakProvider
        authClient={keycloak}
        initOptions={initOptions}
        LoadingComponent={<InitializeDimmer />}
      >
        <UserDispatchFilter>{props.children}</UserDispatchFilter>
      </KeycloakProvider>
    </UserProvider>
  )
}

const UserDispatchFilter: React.FC = props => {
  const { children } = props

  const { status } = useUser()

  const userDispatch = useUserDispatch()
  const { keycloak } = useKeycloak()

  useEffect(() => {
    const updateCurrentUser = async (user?: KeycloakProfile) => {
      if (user) {
        userDispatch({
          type: 'COMPLETE_LOGIN',
          user: {
            ...user,
            authenticated: true,
            sub: keycloak.tokenParsed ? keycloak.tokenParsed.sub : null,
            roles: keycloak.tokenParsed
              ? keycloak.tokenParsed.realm_access?.roles
              : null,
          },
        })
        return
      }

      try {
        const user: KeycloakProfile = await keycloak.loadUserProfile()

        userDispatch({
          type: 'COMPLETE_LOGIN',
          user: {
            ...user,
            authenticated: true,
            sub: keycloak.tokenParsed ? keycloak.tokenParsed.sub : null,
            roles: keycloak.tokenParsed
              ? keycloak.tokenParsed.realm_access?.roles
              : null,
          },
        })
      } catch (err) {
        userDispatch({
          type: 'FAILURE_LOGIN',
        })
      }
    }

    updateCurrentUser()
  }, [keycloak, userDispatch])

  if (status === 'Init' || status === 'Loading') return <InitializeDimmer />

  return <>{children}</>
}

export const PrivateRoute: React.FC<RouteProps> = props => {
  const rest = _.omit(props, ['component'])

  const { keycloak } = useKeycloak()

  return (
    <Route
      {...rest}
      render={innerProps =>
        keycloak.authenticated ? (
          <Route {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: innerProps.location },
            }}
          />
        )
      }
    />
  )
}

type LocationState = {
  from: {
    pathname: string
  }
}

export const LoginPage: React.FC = () => {
  const location = useLocation<LocationState>()
  const { status } = useUser()

  const { from } = location.state || { from: { pathname: '/' } }

  const redirectUri = `${window.location.protocol}//${window.location.host}${from.pathname}`

  if (status === 'Authenticated') return <Redirect to={from} />

  return (
    <Dimmer active page>
      <Header as='h1' inverted>
        <Icon name='key' />
        <Header.Content>
          このページを開くにはサインインが必要です
          <Header.Subheader>
            ボタンを押してサインインしてください
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Divider />
      <Button
        color='teal'
        fluid
        size='massive'
        onClick={() => keycloak.login({ redirectUri })}
      >
        <Icon name='sign in' />
        サインイン
      </Button>
    </Dimmer>
  )
}

type InitializeDimmerProps = {
  icon?: SemanticICONS
}

export const InitializeDimmer: React.FC<InitializeDimmerProps> = props => {
  const { icon } = props

  return (
    <Dimmer active page>
      <Icon loading name={icon} />
      User Initializing....
    </Dimmer>
  )
}

InitializeDimmer.defaultProps = {
  icon: 'spinner',
}

export default keycloak
